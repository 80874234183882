import { TableCell, TableRow } from "@mui/material";
import { useLmsCpsModule } from "global-analytics";
import { Spinner } from "../../../../../components/Spinner";
import { decimal } from "global-analytics";

export function AnnualRenewalsCountRow() {
	const { annualRenewalsCount, annualRenewalsRef } = useLmsCpsModule();

	return (
		<TableRow ref={annualRenewalsRef}>
			<TableCell align="left">Anuais</TableCell>
			{typeof annualRenewalsCount === "number" ? (
				<TableCell align="right">
					{decimal.format(annualRenewalsCount)}
				</TableCell>
			) : (
				<TableCell align="right">
					<Spinner />
				</TableCell>
			)}
		</TableRow>
	);
}
