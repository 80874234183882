"use client";

import { Icon } from "@iconify/react";
import { Box, useTheme } from "@mui/material";

export function LoaderUI() {
	const theme = useTheme();

	return (
		<Box
			sx={{
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				margin: "auto",
			}}
		>
			<Icon
				icon="svg-spinners:6-dots-rotate"
				color={theme.palette.primary.main}
				fontSize={56}
			/>
		</Box>
	);
}
