import { Grid, Table, TableBody, TableCell, TableRow } from "@mui/material";
import Widget from "../../../../components/Widget";
import { Block } from "../../../../components/Block";
import {
	BRL,
	decimalPercent,
	useCommCpsModule,
	useMarketingCpsModule,
} from "global-analytics";
import { Spinner } from "../../../../components/Spinner";

export function AdsBlock() {
	return (
		<Block title="Anúncios" icon="material-symbols:ads-click">
			<Grid container spacing={1}>
				<SpentPerTagWidget />
				<IndexesWidget />
			</Grid>
		</Block>
	);
}

function useLocalState() {
	const {
		facebookConversionsAmount,
		facebookEngagementAmount,
		facebookRemarketingSalesAmount,

		googleConversionsAmount,
		googleEngagementAmount,
		googleRemarketingSalesAmount,
	} = useMarketingCpsModule();

	const totalConversionsAmount =
		typeof facebookConversionsAmount === "number" &&
		typeof googleConversionsAmount === "number"
			? facebookConversionsAmount + googleConversionsAmount
			: null;

	const totalEngagementAmount =
		typeof facebookEngagementAmount === "number" &&
		typeof googleEngagementAmount === "number"
			? facebookEngagementAmount + googleEngagementAmount
			: null;

	const totalRemarketingSalesAmount =
		typeof facebookRemarketingSalesAmount === "number" &&
		typeof googleRemarketingSalesAmount === "number"
			? facebookRemarketingSalesAmount + googleRemarketingSalesAmount
			: null;

	const totalAmount =
		typeof totalConversionsAmount === "number" &&
		typeof totalEngagementAmount === "number" &&
		typeof totalRemarketingSalesAmount === "number"
			? totalConversionsAmount +
				totalEngagementAmount +
				totalRemarketingSalesAmount
			: null;

	let totalConversionsPercent: number | null = null;
	let totalEngagementPercent: number | null = null;
	let totalRemarketingSalesPercent: number | null = null;

	if (typeof totalAmount === "number" && totalAmount > 0) {
		totalConversionsPercent = totalConversionsAmount! / totalAmount;
		totalEngagementPercent = totalEngagementAmount! / totalAmount;
		totalRemarketingSalesPercent = totalRemarketingSalesAmount! / totalAmount;
	} else if (typeof totalAmount === "number") {
		totalConversionsPercent = 0.0;
		totalEngagementPercent = 0.0;
		totalRemarketingSalesPercent = 0.0;
	}

	return {
		totalAmount,
		totalConversionsAmount,
		totalEngagementAmount,
		totalRemarketingSalesAmount,
		totalConversionsPercent,
		totalEngagementPercent,
		totalRemarketingSalesPercent,

		facebookConversionsAmount,
		facebookEngagementAmount,
		facebookRemarketingSalesAmount,

		googleConversionsAmount,
		googleEngagementAmount,
		googleRemarketingSalesAmount,
	};
}

function SpentPerTagWidget() {
	const {
		totalAmount,
		totalConversionsPercent,
		totalEngagementPercent,
		totalRemarketingSalesPercent,

		facebookConversionsAmount,
		facebookEngagementAmount,
		facebookRemarketingSalesAmount,
		googleConversionsAmount,
		googleEngagementAmount,
		googleRemarketingSalesAmount,
	} = useLocalState();
	const {
		facebookConversionsRef,
		facebookEngagementRef,
		facebookRemarketingSalesRef,
		googleConversionsRef,
		googleEngagementRef,
		googleRemarketingSalesRef,
	} = useMarketingCpsModule();

	return (
		<Grid item md={6} xs={12}>
			<Widget title="Custos">
				<Table size="small">
					<TableBody>
						<TableRow>
							<TableCell variant="head">Total</TableCell>
							<TableCell variant="head" align="right">
								{typeof totalAmount === "number" ? (
									BRL.format(totalAmount)
								) : (
									<Spinner />
								)}
							</TableCell>
						</TableRow>
					</TableBody>
				</Table>
				<Table size="small" sx={{ mt: 1 }}>
					<TableBody>
						<TableRow>
							<TableCell variant="head">Captação</TableCell>
							<TableCell align="right">
								{typeof totalConversionsPercent === "number" ? (
									decimalPercent(totalConversionsPercent)
								) : (
									<Spinner />
								)}
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>Facebook</TableCell>
							<TableCell align="right" ref={facebookConversionsRef}>
								{typeof facebookConversionsAmount === "number" ? (
									BRL.format(facebookConversionsAmount)
								) : (
									<Spinner />
								)}
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>Google</TableCell>
							<TableCell align="right" ref={googleConversionsRef}>
								{typeof googleConversionsAmount === "number" ? (
									BRL.format(googleConversionsAmount)
								) : (
									<Spinner />
								)}
							</TableCell>
						</TableRow>
					</TableBody>
				</Table>
				<Table size="small" sx={{ mt: 1 }}>
					<TableBody>
						<TableRow>
							<TableCell variant="head">Engajamento</TableCell>
							<TableCell align="right">
								{typeof totalEngagementPercent === "number" ? (
									decimalPercent(totalEngagementPercent)
								) : (
									<Spinner />
								)}
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>Facebook</TableCell>
							<TableCell align="right" ref={facebookEngagementRef}>
								{typeof facebookEngagementAmount === "number" ? (
									BRL.format(facebookEngagementAmount)
								) : (
									<Spinner />
								)}
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>Google</TableCell>
							<TableCell align="right" ref={googleEngagementRef}>
								{typeof googleEngagementAmount === "number" ? (
									BRL.format(googleEngagementAmount)
								) : (
									<Spinner />
								)}
							</TableCell>
						</TableRow>
					</TableBody>
				</Table>
				<Table size="small" sx={{ mt: 1 }}>
					<TableBody>
						<TableRow>
							<TableCell variant="head">RMKT | Venda</TableCell>
							<TableCell align="right">
								{typeof totalRemarketingSalesPercent === "number" ? (
									decimalPercent(totalRemarketingSalesPercent)
								) : (
									<Spinner />
								)}
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>Facebook</TableCell>
							<TableCell align="right" ref={facebookRemarketingSalesRef}>
								{typeof facebookRemarketingSalesAmount === "number" ? (
									BRL.format(facebookRemarketingSalesAmount)
								) : (
									<Spinner />
								)}
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>Google</TableCell>
							<TableCell align="right" ref={googleRemarketingSalesRef}>
								{typeof googleRemarketingSalesAmount === "number" ? (
									BRL.format(googleRemarketingSalesAmount)
								) : (
									<Spinner />
								)}
							</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</Widget>
		</Grid>
	);
}

function IndexesWidget() {
	const { totalAmount, totalConversionsAmount } = useLocalState();
	const { leadHolderCount, visitorHolderCount } = useMarketingCpsModule();
	const { annualCount, semiannualCount } = useCommCpsModule();

	const holderCount =
		typeof leadHolderCount === "number" &&
		typeof visitorHolderCount === "number"
			? leadHolderCount + visitorHolderCount
			: null;

	const relevantOrdersCount =
		typeof annualCount === "number" && typeof semiannualCount === "number"
			? annualCount + semiannualCount
			: null;

	// Indexes:
	let conversionsIndex: number | null = null;
	if (
		typeof relevantOrdersCount === "number" &&
		typeof visitorHolderCount === "number"
	) {
		conversionsIndex =
			visitorHolderCount > 0.0 ? relevantOrdersCount / visitorHolderCount : 0.0;
	}

	let cpfIndex: number | null = null;
	if (
		typeof totalConversionsAmount === "number" &&
		typeof holderCount === "number"
	) {
		cpfIndex = holderCount > 0.0 ? totalConversionsAmount / holderCount : 0.0;
	}

	let cpnlIndex: number | null = null;
	if (
		typeof totalConversionsAmount === "number" &&
		typeof visitorHolderCount === "number"
	) {
		cpnlIndex =
			visitorHolderCount > 0.0
				? totalConversionsAmount / visitorHolderCount
				: 0.0;
	}

	let cacMarketingIndex: number | null = null;
	if (
		typeof totalAmount === "number" &&
		typeof relevantOrdersCount === "number"
	) {
		cacMarketingIndex =
			relevantOrdersCount > 0.0 ? totalAmount / relevantOrdersCount : 0.0;
	}

	return (
		<Grid item md={6} xs={12}>
			<Widget title="Métricas">
				<Table size="small">
					<TableBody>
						<TableRow>
							<TableCell align="left">Taxa de Conversão</TableCell>
							<TableCell align="right">
								{typeof conversionsIndex === "number" ? (
									decimalPercent(conversionsIndex)
								) : (
									<Spinner />
								)}
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell align="left">CPF (Formulário)</TableCell>
							<TableCell align="right">
								{typeof cpfIndex === "number" ? (
									BRL.format(cpfIndex)
								) : (
									<Spinner />
								)}
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell align="left">CPNL (Novo Lead)</TableCell>
							<TableCell align="right">
								{typeof cpnlIndex === "number" ? (
									BRL.format(cpnlIndex)
								) : (
									<Spinner />
								)}
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell align="left">CAC (Mkt)</TableCell>
							<TableCell align="right">
								{typeof cacMarketingIndex === "number" ? (
									BRL.format(cacMarketingIndex)
								) : (
									<Spinner />
								)}
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell align="left">CAC (Empresa)</TableCell>
							<TableCell align="right">R$ 0,00</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</Widget>
		</Grid>
	);
}
