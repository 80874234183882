import { TableCell, TableRow } from "@mui/material";
import { decimalPercent, useCommCpsModule } from "global-analytics";
import { Spinner } from "../../../../../components/Spinner";

export function BoletoPercentRow() {
	const { boletoPercent, boletoRef } = useCommCpsModule();

	return (
		<TableRow ref={boletoRef}>
			<TableCell align="left">Boleto</TableCell>
			{typeof boletoPercent === "number" ? (
				<TableCell align="right">{decimalPercent(boletoPercent)}</TableCell>
			) : (
				<TableCell align="right">
					<Spinner />
				</TableCell>
			)}
		</TableRow>
	);
}
