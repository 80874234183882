import { TableCell, TableRow } from "@mui/material";
import { useCommCpsModule } from "global-analytics";
import { BRL } from "global-analytics";
import { Spinner } from "../../../../../components/Spinner";

export function ProfitFeesAmountRow() {
	const { profitFeesAmount, profitFeesRef } = useCommCpsModule();

	return (
		<TableRow ref={profitFeesRef}>
			<TableCell>Hotmart</TableCell>
			{typeof profitFeesAmount === "number" ? (
				<TableCell align="right">
					{BRL.format(profitFeesAmount / 100)}
				</TableCell>
			) : (
				<TableCell align="right">
					<Spinner />
				</TableCell>
			)}
		</TableRow>
	);
}
