import moment from "moment-timezone";
import { CoveragePosition } from "./types";
import { FilterState } from "../contracts/filters";

export const coverageFromRange = (
	range: FilterState["range"],
	dir: "start" | "end",
): CoveragePosition => {
	const momentInp = range[dir === "start" ? 0 : 1];
	const coverageMoment = moment(momentInp).tz("America/Sao_Paulo");
	const coveragePositionMoment =
		dir === "start"
			? coverageMoment.startOf("day")
			: coverageMoment.endOf("day");

	return {
		year: coveragePositionMoment.format("YYYY"),
		month: coveragePositionMoment.format("YYYY-MM"),
		day: coveragePositionMoment.format("YYYY-MM-DD"),
	};
};
