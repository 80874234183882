export const BRL = new Intl.NumberFormat("pt-BR", {
	style: "currency",
	currency: "BRL",
});

export const decimal = new Intl.NumberFormat("pt-BR");

export const decimalPercent = (number: number): string => {
	const percentString = (Math.round(number * 1000) / 10)
		.toFixed(1)
		.replace(".", ",");

	return `${percentString}%`;
};
