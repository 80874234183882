"use client";

import { Box, Chip, Typography, alpha, styled } from "@mui/material";
import { useAuth } from "../auth";
import Image from "next/image";
import { User } from "../types";
import { useGlobalAnalytics } from "global-analytics";
import moment from "moment-timezone";

export default function ToolBar() {
	const { user } = useAuth();

	return (
		<Box
			sx={(theme) => ({
				position: "fixed",
				left: 0,
				bottom: 0,
				width: "100%",
				p: theme.spacing(1),
				backgroundColor: alpha(theme.palette.background.default, 0.6),
				backdropFilter: "blur(2px)",

				display: "grid",
				alignItems: "center",
				"& > *": {
					gridRowStart: 1,
					gridColumnStart: 1,
				},
			})}
		>
			{user && <UserBox user={user} />}
			<FiltersBox />
		</Box>
	);
}

const CustomAvatar = styled(Image)(({ theme }) => ({
	borderRadius: "50%",
}));

function UserBox({ user }: { user: User }) {
	return (
		<Box
			sx={{
				display: "flex",
				alignItems: "center",
				gap: 1,
				justifySelf: "start",
			}}
		>
			{user.avatarUrl && (
				<CustomAvatar
					src={user.avatarUrl}
					width={24}
					height={24}
					alt={user.firstName ?? ""}
				/>
			)}
			<Typography variant="body1" sx={{ mb: "-5px" }}>
				{user.firstName}
			</Typography>
		</Box>
	);
}

function FiltersBox() {
	const { filterState } = useGlobalAnalytics();
	const [rangeFrom, rangeTo] = filterState.range;

	const momentFrom = moment(rangeFrom).tz("America/Sao_Paulo");
	const momentTo = moment(rangeTo).tz("America/Sao_Paulo");

	return (
		<Box
			sx={{
				justifySelf: "end",
			}}
		>
			<Chip label={momentFrom.format("DD/MM/YYYY")} />
			<span>&nbsp;à&nbsp;</span>
			<Chip label={momentTo.format("DD/MM/YYYY")} />
		</Box>
	);
}
