import {
	Grid,
	Table,
	TableHead,
	TableBody,
	TableCell,
	TableRow,
} from "@mui/material";
import Widget from "../../../../components/Widget";
import { Block } from "../../../../components/Block";
import {
	decimal,
	decimalPercent,
	useMarketingCpsModule,
} from "global-analytics";
import { Spinner } from "../../../../components/Spinner";

function ValuesWidget() {
	const { leadHolderCount, visitorHolderCount } = useMarketingCpsModule();

	const totalCount =
		typeof leadHolderCount === "number" &&
		typeof visitorHolderCount === "number"
			? leadHolderCount + visitorHolderCount
			: null;

	return (
		<Widget>
			<Table size="small">
				<TableHead>
					<TableRow>
						<TableCell variant="head">Formulários</TableCell>
						<TableCell variant="head">Novos Leads</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					<TableRow>
						<TableCell>
							{typeof totalCount === "number" ? (
								decimal.format(totalCount)
							) : (
								<Spinner />
							)}
						</TableCell>
						<TableCell>
							{typeof visitorHolderCount === "number" ? (
								decimal.format(visitorHolderCount)
							) : (
								<Spinner />
							)}
						</TableCell>
					</TableRow>
				</TableBody>
			</Table>
		</Widget>
	);
}

function CategoriesWidget() {
	const {
		paidMedBrConversionsCount,
		paidMedExtConversionsCount,
		paidPdConversionsCount,
		paidPdMedBrConversionsCount,
		paidPdMedExtConversionsCount,
		paidEocConversionsCount,
		paidOthersConversionsCount,

		organicMedBrConversionsCount,
		organicMedExtConversionsCount,
		organicPdConversionsCount,
		organicPdMedBrConversionsCount,
		organicPdMedExtConversionsCount,
		organicEocConversionsCount,
		organicOthersConversionsCount,
	} = useMarketingCpsModule();

	return (
		<Widget title="Categorias">
			<Table size="small">
				<TableHead>
					<TableRow>
						<TableCell></TableCell>
						<TableCell>Pago</TableCell>
						<TableCell>Orgânico</TableCell>
						<TableCell>Total</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					<TableRow>
						<TableCell>BR</TableCell>
						<TableCell>
							{typeof paidMedBrConversionsCount === "number" ? (
								decimal.format(paidMedBrConversionsCount)
							) : (
								<Spinner />
							)}
						</TableCell>
						<TableCell>
							{typeof organicMedBrConversionsCount === "number" ? (
								decimal.format(organicMedBrConversionsCount)
							) : (
								<Spinner />
							)}
						</TableCell>
						<TableCell>
							{typeof paidMedBrConversionsCount === "number" &&
							typeof organicMedBrConversionsCount === "number" ? (
								decimal.format(
									paidMedBrConversionsCount + organicMedBrConversionsCount,
								)
							) : (
								<Spinner />
							)}
						</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>EXT</TableCell>
						<TableCell>
							{typeof paidMedExtConversionsCount === "number" ? (
								decimal.format(paidMedExtConversionsCount)
							) : (
								<Spinner />
							)}
						</TableCell>
						<TableCell>
							{typeof organicMedExtConversionsCount === "number" ? (
								decimal.format(organicMedExtConversionsCount)
							) : (
								<Spinner />
							)}
						</TableCell>
						<TableCell>
							{typeof paidMedExtConversionsCount === "number" &&
							typeof organicMedExtConversionsCount === "number" ? (
								decimal.format(
									paidMedExtConversionsCount + organicMedExtConversionsCount,
								)
							) : (
								<Spinner />
							)}
						</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>PD</TableCell>
						<TableCell>
							{typeof paidPdConversionsCount === "number" ? (
								decimal.format(paidPdConversionsCount)
							) : (
								<Spinner />
							)}
						</TableCell>
						<TableCell>
							{typeof organicPdConversionsCount === "number" ? (
								decimal.format(organicPdConversionsCount)
							) : (
								<Spinner />
							)}
						</TableCell>
						<TableCell>
							{typeof paidPdConversionsCount === "number" &&
							typeof organicPdConversionsCount === "number" ? (
								decimal.format(
									paidPdConversionsCount + organicPdConversionsCount,
								)
							) : (
								<Spinner />
							)}
						</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>EOC</TableCell>
						<TableCell>
							{typeof paidEocConversionsCount === "number" ? (
								decimal.format(paidEocConversionsCount)
							) : (
								<Spinner />
							)}
						</TableCell>
						<TableCell>
							{typeof organicEocConversionsCount === "number" ? (
								decimal.format(organicEocConversionsCount)
							) : (
								<Spinner />
							)}
						</TableCell>
						<TableCell>
							{typeof paidEocConversionsCount === "number" &&
							typeof organicEocConversionsCount === "number" ? (
								decimal.format(
									paidEocConversionsCount + organicEocConversionsCount,
								)
							) : (
								<Spinner />
							)}
						</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>PD + BR</TableCell>
						<TableCell>
							{typeof paidPdMedBrConversionsCount === "number" ? (
								decimal.format(paidPdMedBrConversionsCount)
							) : (
								<Spinner />
							)}
						</TableCell>
						<TableCell>
							{typeof organicPdMedBrConversionsCount === "number" ? (
								decimal.format(organicPdMedBrConversionsCount)
							) : (
								<Spinner />
							)}
						</TableCell>
						<TableCell>
							{typeof paidPdMedBrConversionsCount === "number" &&
							typeof organicPdMedBrConversionsCount === "number" ? (
								decimal.format(
									paidPdMedBrConversionsCount + organicPdMedBrConversionsCount,
								)
							) : (
								<Spinner />
							)}
						</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>PD + EXT</TableCell>
						<TableCell>
							{typeof paidPdMedExtConversionsCount === "number" ? (
								decimal.format(paidPdMedExtConversionsCount)
							) : (
								<Spinner />
							)}
						</TableCell>
						<TableCell>
							{typeof organicPdMedExtConversionsCount === "number" ? (
								decimal.format(organicPdMedExtConversionsCount)
							) : (
								<Spinner />
							)}
						</TableCell>
						<TableCell>
							{typeof paidPdMedExtConversionsCount === "number" &&
							typeof organicPdMedExtConversionsCount === "number" ? (
								decimal.format(
									paidPdMedExtConversionsCount +
										organicPdMedExtConversionsCount,
								)
							) : (
								<Spinner />
							)}
						</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>Outros</TableCell>
						<TableCell>
							{typeof paidOthersConversionsCount === "number" ? (
								decimal.format(paidOthersConversionsCount)
							) : (
								<Spinner />
							)}
						</TableCell>
						<TableCell>
							{typeof organicOthersConversionsCount === "number" ? (
								decimal.format(organicOthersConversionsCount)
							) : (
								<Spinner />
							)}
						</TableCell>
						<TableCell>
							{typeof paidOthersConversionsCount === "number" &&
							typeof organicOthersConversionsCount === "number" ? (
								decimal.format(
									paidOthersConversionsCount + organicOthersConversionsCount,
								)
							) : (
								<Spinner />
							)}
						</TableCell>
					</TableRow>
				</TableBody>
			</Table>
		</Widget>
	);
}

function MetricsWidget() {
	const {
		googlePaidMediumConversionsCount,
		facebookPaidMediumConversionsCount,
		organicMediumConversionsCount,
	} = useMarketingCpsModule();

	const totalPaidMediumConversionsCount =
		typeof googlePaidMediumConversionsCount === "number" &&
		typeof facebookPaidMediumConversionsCount === "number"
			? googlePaidMediumConversionsCount + facebookPaidMediumConversionsCount
			: null;

	const totalMediumConversionsCount =
		typeof totalPaidMediumConversionsCount === "number" &&
		typeof organicMediumConversionsCount === "number"
			? totalPaidMediumConversionsCount + organicMediumConversionsCount
			: null;

	const totalPaidMediumPercent =
		typeof totalPaidMediumConversionsCount === "number" &&
		typeof totalMediumConversionsCount === "number"
			? totalMediumConversionsCount > 0
				? totalPaidMediumConversionsCount / totalMediumConversionsCount
				: 0.0
			: null;

	const totalOrganicMediumPercent =
		typeof organicMediumConversionsCount === "number" &&
		typeof totalMediumConversionsCount === "number"
			? totalMediumConversionsCount > 0
				? organicMediumConversionsCount / totalMediumConversionsCount
				: 0.0
			: null;

	return (
		<Widget title="Métricas">
			<Table size="small">
				<TableHead>
					<TableRow>
						<TableCell align="left">Pagos</TableCell>
						<TableCell align="right" variant="body">
							{typeof totalPaidMediumPercent === "number" ? (
								decimalPercent(totalPaidMediumPercent)
							) : (
								<Spinner />
							)}
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					<TableRow>
						<TableCell align="left">Google</TableCell>
						<TableCell align="right">
							{typeof googlePaidMediumConversionsCount === "number" ? (
								decimal.format(googlePaidMediumConversionsCount)
							) : (
								<Spinner />
							)}
						</TableCell>
					</TableRow>
					<TableRow>
						<TableCell align="left">Facebook</TableCell>
						<TableCell align="right">
							{typeof facebookPaidMediumConversionsCount === "number" ? (
								decimal.format(facebookPaidMediumConversionsCount)
							) : (
								<Spinner />
							)}
						</TableCell>
					</TableRow>
				</TableBody>
			</Table>
			<Table size="small" sx={{ mt: 1 }}>
				<TableHead>
					<TableRow>
						<TableCell align="left">Orgânicos</TableCell>
						<TableCell align="right" variant="body">
							{typeof totalOrganicMediumPercent === "number" ? (
								decimalPercent(totalOrganicMediumPercent)
							) : (
								<Spinner />
							)}
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					<TableRow>
						<TableCell align="left">Total</TableCell>
						<TableCell align="right">
							{typeof organicMediumConversionsCount === "number" ? (
								decimal.format(organicMediumConversionsCount)
							) : (
								<Spinner />
							)}
						</TableCell>
					</TableRow>
				</TableBody>
			</Table>
		</Widget>
	);
}

export function ConversionsBlock() {
	return (
		<Block title="Cadastros" icon="ic:outline-email">
			<Grid container spacing={1}>
				<Grid item md={4} xs={12}>
					<MetricsWidget />
				</Grid>
				<Grid item md={8} xs={12}>
					<ValuesWidget />
					<CategoriesWidget />
				</Grid>
			</Grid>
		</Block>
	);
}
