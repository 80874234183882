import {
	Table,
	TableHead,
	TableBody,
	TableCell,
	TableRow,
} from "@mui/material";
import Widget from "../../../../components/Widget";
import { Block } from "../../../../components/Block";
import { decimal, useFunnelCpsModule } from "global-analytics";
import { Spinner } from "../../../../components/Spinner";
import { Theme } from "@mui/material";

function sumTotalColumn(values: (number | null)[]) {
	if (values.some((value) => value === null)) {
		return null;
	}

	return (values as number[]).reduce((acc, value) => {
		if (value === null) return acc;

		return acc + value;
	}, 0.0);
}

function TotalRow() {
	const {
		top,
		middle,
		bottom,
		// student_reserved,
		customer_active,
		customer_inactive,
		others,
		inactivations_per_audience,
	} = useFunnelCpsModule();

	const topValue = sumTotalColumn(Object.values(top));
	const middleValue = sumTotalColumn(Object.values(middle));
	const bottomValue = sumTotalColumn(Object.values(bottom));
	// const studentReservedValue = sumTotalColumn(Object.values(student_reserved));
	const customerActiveValue = sumTotalColumn(Object.values(customer_active));
	const customerInactiveValue = sumTotalColumn(
		Object.values(customer_inactive),
	);
	const othersValue = sumTotalColumn(Object.values(others));
	const inactivationsPerAudienceValue = sumTotalColumn(
		Object.values(inactivations_per_audience),
	);

	return (
		<TableRow>
			<TableCell variant="head">Total</TableCell>
			<TableCell variant="head">
				{typeof topValue === "number" ? decimal.format(topValue) : <Spinner />}
			</TableCell>
			<TableCell variant="head">
				{typeof middleValue === "number" ? (
					decimal.format(middleValue)
				) : (
					<Spinner />
				)}
			</TableCell>
			<TableCell variant="head">
				{typeof bottomValue === "number" ? (
					decimal.format(bottomValue)
				) : (
					<Spinner />
				)}
			</TableCell>
			{/* <TableCell variant="head">
				{typeof studentReservedValue === "number" ? (
					decimal.format(studentReservedValue)
				) : (
					<Spinner />
				)}
			</TableCell> */}
			<TableCell variant="head">
				{typeof customerActiveValue === "number" ? (
					decimal.format(customerActiveValue)
				) : (
					<Spinner />
				)}
			</TableCell>
			<TableCell variant="head">
				{typeof customerInactiveValue === "number" ? (
					decimal.format(customerInactiveValue)
				) : (
					<Spinner />
				)}
			</TableCell>
			<TableCell variant="head">
				{typeof othersValue === "number" ? (
					decimal.format(othersValue)
				) : (
					<Spinner />
				)}
			</TableCell>
			<TableCell variant="head" sx={dividerSx}>
				{typeof inactivationsPerAudienceValue === "number" ? (
					decimal.format(inactivationsPerAudienceValue)
				) : (
					<Spinner />
				)}
			</TableCell>
		</TableRow>
	);
}

const dividerSx = (theme: Theme) => ({
	borderLeft: `1px solid ${theme.palette.divider}`,
});

function CategoryRow({
	title,
	category,
}: {
	title: string;
	category: string; //
}) {
	const {
		top,
		middle,
		bottom,
		// student_reserved,
		customer_active,
		customer_inactive,
		others,
		inactivations_per_audience,
	} = useFunnelCpsModule();

	const topValue = top[category];
	const middleValue = middle[category];
	const bottomValue = bottom[category];
	// const studentReservedValue = student_reserved[category];
	const customerActiveValue = customer_active[category];
	const customerInactiveValue = customer_inactive[category];
	const othersValue = others[category];
	const inactivationsPerAudienceValue = inactivations_per_audience[category];

	return (
		<TableRow>
			<TableCell>{title}</TableCell>
			<TableCell>
				{typeof topValue === "number" ? decimal.format(topValue) : <Spinner />}
			</TableCell>
			<TableCell>
				{typeof middleValue === "number" ? (
					decimal.format(middleValue)
				) : (
					<Spinner />
				)}
			</TableCell>
			<TableCell>
				{typeof bottomValue === "number" ? (
					decimal.format(bottomValue)
				) : (
					<Spinner />
				)}
			</TableCell>
			<TableCell>
				{typeof customerActiveValue === "number" ? (
					decimal.format(customerActiveValue)
				) : (
					<Spinner />
				)}
			</TableCell>
			<TableCell>
				{typeof customerInactiveValue === "number" ? (
					decimal.format(customerInactiveValue)
				) : (
					<Spinner />
				)}
			</TableCell>
			<TableCell>
				{typeof othersValue === "number" ? (
					decimal.format(othersValue)
				) : (
					<Spinner />
				)}
			</TableCell>
			<TableCell sx={dividerSx}>
				{typeof inactivationsPerAudienceValue === "number" ? (
					decimal.format(inactivationsPerAudienceValue)
				) : (
					<Spinner />
				)}
			</TableCell>
		</TableRow>
	);
}

export function FunnelBlock() {
	return (
		<Block title="Funil" icon="mdi:funnel-outline" immediate>
			<Widget>
				<Table size="small">
					<TableHead>
						<TableRow>
							<TableCell></TableCell>
							<TableCell>Topo</TableCell>
							<TableCell>Meio</TableCell>
							<TableCell>Fundo</TableCell>
							<TableCell>AA</TableCell>
							<TableCell>EC</TableCell>
							<TableCell>SI</TableCell>
							<TableCell sx={dividerSx}>VM</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						<TotalRow />
						<CategoryRow title="BR" category="med-br" />
						<CategoryRow title="EXT" category="med-ext" />
						<CategoryRow title="PD" category="pd" />
						<CategoryRow title="EOC" category="eoc" />
						<CategoryRow title="PD + BR" category="pd-med-br" />
						<CategoryRow title="PD + EXT" category="pd-med-ext" />
						<CategoryRow title="Outros" category="others" />
					</TableBody>
				</Table>
			</Widget>
		</Block>
	);
}
