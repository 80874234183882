/* eslint-disable @typescript-eslint/no-non-null-assertion */
"use client";

import { RefObject, useRef } from "react";
import { coverageFromRange } from "../cps/coverage-factory";
import { useSumValuesRange } from "../cps/range-hooks";
import { useGlobalAnalytics } from "../store/context";
import { moduleName } from "./constants";
import { useInView } from "framer-motion";

export type PaymentMethodValue = {
	// Credit card
	creditCardRef: RefObject<HTMLTableRowElement>;
	creditCardPercent: number | null;

	// Pix
	pixRef: RefObject<HTMLTableRowElement>;
	pixPercent: number | null;

	// Boleto
	boletoRef: RefObject<HTMLTableRowElement>;
	boletoPercent: number | null;
};

export const paymentMethodDefault: PaymentMethodValue = {
	creditCardRef: { current: null },
	creditCardPercent: null,
	pixRef: { current: null },
	pixPercent: null,
	boletoRef: { current: null },
	boletoPercent: null,
};

export const usePaymentMethod = () => {
	const { filterState } = useGlobalAnalytics();
	const coverageFrom = coverageFromRange(filterState.range, "start");
	const coverageTo = coverageFromRange(filterState.range, "end");

	// Credit card
	const creditCardRef = useRef<HTMLTableRowElement>(null);
	const creditCardCount = useSumValuesRange(
		moduleName,
		"payment-methods-count",
		coverageFrom,
		coverageTo,
		{ "payment-method": ["credit-card"] },
		useInView(creditCardRef),
	);
	const creditCardCountSum = creditCardCount.data?.sum;

	// Pix
	const pixRef = useRef<HTMLTableRowElement>(null);
	const pixCount = useSumValuesRange(
		moduleName,
		"payment-methods-count",
		coverageFrom,
		coverageTo,
		{ "payment-method": ["pix"] },
		useInView(pixRef),
	);
	const pixCountSum = pixCount.data?.sum;

	// Boleto
	const boletoRef = useRef<HTMLTableRowElement>(null);
	const boletoCount = useSumValuesRange(
		moduleName,
		"payment-methods-count",
		coverageFrom,
		coverageTo,
		{ "payment-method": ["boleto"] },
		useInView(boletoRef),
	);
	const boletoCountSum = boletoCount.data?.sum;

	if (
		creditCardCount.isLoading ||
		creditCardCount.error ||
		pixCount.isLoading ||
		pixCount.error ||
		boletoCount.isLoading ||
		boletoCount.error
	) {
		return {
			creditCardRef,
			creditCardPercent: null,
			pixRef,
			pixPercent: null,
			boletoRef,
			boletoPercent: null,
		};
	}

	if (
		[
			typeof creditCardCountSum === "number",
			typeof pixCountSum === "number",
			typeof boletoCountSum === "number",
		].some((value) => !value)
	) {
		return {
			creditCardRef,
			creditCardPercent: 0.0,
			pixRef,
			pixPercent: 0.0,
			boletoRef,
			boletoPercent: 0.0,
		};
	}

	const total = creditCardCountSum! + pixCountSum! + boletoCountSum!;

	if (total === 0) {
		return {
			creditCardRef,
			creditCardPercent: 0,
			pixRef,
			pixPercent: 0.0,
			boletoRef,
			boletoPercent: 0.0,
		};
	}

	return {
		creditCardRef,
		creditCardPercent: creditCardCountSum! / total,
		pixRef,
		pixPercent: pixCountSum! / total,
		boletoRef,
		boletoPercent: boletoCountSum! / total,
	};
};
