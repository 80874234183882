import { TableCell, TableRow } from "@mui/material";
import { useCommCpsModule } from "global-analytics";
import { Spinner } from "../../../../../components/Spinner";
import { decimal } from "global-analytics";

export function ComplementCountRow() {
	const { complementCount, complementRef } = useCommCpsModule();

	return (
		<TableRow ref={complementRef}>
			<TableCell align="left">Complementos</TableCell>
			{typeof complementCount === "number" ? (
				<TableCell align="right">{decimal.format(complementCount)}</TableCell>
			) : (
				<TableCell align="right">
					<Spinner />
				</TableCell>
			)}
		</TableRow>
	);
}
