"use client";

import { coverageFromRange } from "../cps/coverage-factory";
import { useSumValuesRange } from "../cps/range-hooks";
import { CoveragePosition } from "../cps/types";
import { useGlobalAnalytics } from "../store/context";
import { moduleName } from "./constants";

export type GrowthCountValue = {
	balanceCount: number | null;
	increaseCount: number | null;
	decreaseCount: number | null;
};

export const growthCountDefault: GrowthCountValue = {
	balanceCount: null,
	increaseCount: null,
	decreaseCount: null,
};

const useGrowthSum = (
	coverageFrom: CoveragePosition,
	coverageTo: CoveragePosition,
	axis: "increase" | "decrease" | "balance",
) => {
	const count = useSumValuesRange(
		moduleName,
		"growth-count",
		coverageFrom,
		coverageTo,
		{ axis: [axis] },
	);

	return count.isLoading || count.error ? null : count.data?.sum ?? 0.0;
};

export const useGrowthCount = () => {
	const { filterState } = useGlobalAnalytics();
	const from = coverageFromRange(filterState.range, "start");
	const to = coverageFromRange(filterState.range, "end");

	return {
		balanceCount: useGrowthSum(from, to, "balance"),
		increaseCount: useGrowthSum(from, to, "increase"),
		decreaseCount: useGrowthSum(from, to, "decrease"),
	};
};
