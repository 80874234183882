"use client";

import { coverageFromRange } from "../cps/coverage-factory";
import { useSumValuesRange } from "../cps/range-hooks";
import { CoveragePosition } from "../cps/types";
import { useGlobalAnalytics } from "../store/context";
import { moduleName } from "./constants";

export type VisitsCountValue = {
	homeMedBrVisitsCount: number | null;
	homeMedExtVisitsCount: number | null;
	homePdVisitsCount: number | null;
	homePdMedBrVisitsCount: number | null;
	homePdMedExtVisitsCount: number | null;
	homeEocVisitsCount: number | null;
	homeOthersVisitsCount: number | null;
	//
	universitiesMedBrVisitsCount: number | null;
	universitiesMedExtVisitsCount: number | null;
	universitiesPdVisitsCount: number | null;
	universitiesPdMedBrVisitsCount: number | null;
	universitiesPdMedExtVisitsCount: number | null;
	universitiesEocVisitsCount: number | null;
	universitiesOthersVisitsCount: number | null;
	//
	trainingMedBrVisitsCount: number | null;
	trainingMedExtVisitsCount: number | null;
	trainingPdVisitsCount: number | null;
	trainingPdMedBrVisitsCount: number | null;
	trainingPdMedExtVisitsCount: number | null;
	trainingEocVisitsCount: number | null;
	trainingOthersVisitsCount: number | null;
	//
	salesMedBrVisitsCount: number | null;
	salesMedExtVisitsCount: number | null;
	salesPdVisitsCount: number | null;
	salesPdMedBrVisitsCount: number | null;
	salesPdMedExtVisitsCount: number | null;
	salesEocVisitsCount: number | null;
	salesOthersVisitsCount: number | null;
};

export const visitsCountDefault: VisitsCountValue = {
	homeMedBrVisitsCount: null,
	homeMedExtVisitsCount: null,
	homePdVisitsCount: null,
	homePdMedBrVisitsCount: null,
	homePdMedExtVisitsCount: null,
	homeEocVisitsCount: null,
	homeOthersVisitsCount: null,
	//
	universitiesMedBrVisitsCount: null,
	universitiesMedExtVisitsCount: null,
	universitiesPdVisitsCount: null,
	universitiesPdMedBrVisitsCount: null,
	universitiesPdMedExtVisitsCount: null,
	universitiesEocVisitsCount: null,
	universitiesOthersVisitsCount: null,
	//
	trainingMedBrVisitsCount: null,
	trainingMedExtVisitsCount: null,
	trainingPdVisitsCount: null,
	trainingPdMedBrVisitsCount: null,
	trainingPdMedExtVisitsCount: null,
	trainingEocVisitsCount: null,
	trainingOthersVisitsCount: null,
	//
	salesMedBrVisitsCount: null,
	salesMedExtVisitsCount: null,
	salesPdVisitsCount: null,
	salesPdMedBrVisitsCount: null,
	salesPdMedExtVisitsCount: null,
	salesEocVisitsCount: null,
	salesOthersVisitsCount: null,
};

const useVisitsCountSum = (
	coverageFrom: CoveragePosition,
	coverageTo: CoveragePosition,
	page: string | null,
	category: string | null,
) => {
	// Do not use inView! Others metrics composes these metrics.

	const count = useSumValuesRange(
		moduleName,
		"visits-count",
		coverageFrom,
		coverageTo,
		{
			...(page
				? {
						page: [page],
				  }
				: {}),
			...(category
				? {
						category: [category],
				  }
				: {}),
		},
	);

	return count.isLoading || count.error ? null : count.data?.sum ?? 0.0;
};

export const useVisitsCount = () => {
	const { filterState } = useGlobalAnalytics();
	const from = coverageFromRange(filterState.range, "start");
	const to = coverageFromRange(filterState.range, "end");

	return {
		homeMedBrVisitsCount: useVisitsCountSum(from, to, "home", "med-br"),
		homeMedExtVisitsCount: useVisitsCountSum(from, to, "home", "med-ext"),
		homePdVisitsCount: useVisitsCountSum(from, to, "home", "pd"),
		homePdMedBrVisitsCount: useVisitsCountSum(from, to, "home", "pd-med-br"),
		homePdMedExtVisitsCount: useVisitsCountSum(from, to, "home", "pd-med-ext"),
		homeEocVisitsCount: useVisitsCountSum(from, to, "home", "eoc"),
		homeOthersVisitsCount: useVisitsCountSum(from, to, "home", "others"),
		//
		universitiesMedBrVisitsCount: useVisitsCountSum(
			from,
			to,
			"universities",
			"med-br",
		),
		universitiesMedExtVisitsCount: useVisitsCountSum(
			from,
			to,
			"universities",
			"med-ext",
		),
		universitiesPdVisitsCount: useVisitsCountSum(
			from,
			to,
			"universities",
			"pd",
		),
		universitiesPdMedBrVisitsCount: useVisitsCountSum(
			from,
			to,
			"universities",
			"pd-med-br",
		),
		universitiesPdMedExtVisitsCount: useVisitsCountSum(
			from,
			to,
			"universities",
			"pd-med-ext",
		),
		universitiesEocVisitsCount: useVisitsCountSum(
			from,
			to,
			"universities",
			"eoc",
		),
		universitiesOthersVisitsCount: useVisitsCountSum(
			from,
			to,
			"universities",
			"others",
		),
		//
		trainingMedBrVisitsCount: useVisitsCountSum(from, to, "training", "med-br"),
		trainingMedExtVisitsCount: useVisitsCountSum(
			from,
			to,
			"training",
			"med-ext",
		),
		trainingPdVisitsCount: useVisitsCountSum(from, to, "training", "pd"),
		trainingPdMedBrVisitsCount: useVisitsCountSum(
			from,
			to,
			"training",
			"pd-med-br",
		),
		trainingPdMedExtVisitsCount: useVisitsCountSum(
			from,
			to,
			"training",
			"pd-med-ext",
		),
		trainingEocVisitsCount: useVisitsCountSum(from, to, "training", "eoc"),
		trainingOthersVisitsCount: useVisitsCountSum(
			from,
			to,
			"training",
			"others",
		),
		//
		salesMedBrVisitsCount: useVisitsCountSum(from, to, "sales", "med-br"),
		salesMedExtVisitsCount: useVisitsCountSum(from, to, "sales", "med-ext"),
		salesPdVisitsCount: useVisitsCountSum(from, to, "sales", "pd"),
		salesPdMedBrVisitsCount: useVisitsCountSum(from, to, "sales", "pd-med-br"),
		salesPdMedExtVisitsCount: useVisitsCountSum(
			from,
			to,
			"sales",
			"pd-med-ext",
		),
		salesEocVisitsCount: useVisitsCountSum(from, to, "sales", "eoc"),
		salesOthersVisitsCount: useVisitsCountSum(from, to, "sales", "others"),
	};
};
