import { InlineIcon } from "@iconify/react";
import { useTheme } from "@mui/material";

export function Spinner() {
	const theme = useTheme();

	return (
		<InlineIcon
			icon="svg-spinners:ring-resize"
			color={theme.palette.secondary.main}
		/>
	);
}
