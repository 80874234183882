"use client";

import { RefObject, useRef } from "react";
import { coverageFromRange } from "../cps/coverage-factory";
import { useSumValuesRange } from "../cps/range-hooks";
import { useGlobalAnalytics } from "../store/context";
import { moduleName } from "./constants";
import { useInView } from "framer-motion";

export type SummaryValue = {
	// Net profit
	netProfitRef: RefObject<HTMLTableRowElement>;
	netProfitAmount: number | null;

	// Order fees
	orderFeesRef: RefObject<HTMLTableRowElement>;
	orderFeesAmount: number | null;

	// Profit fees
	profitFeesRef: RefObject<HTMLTableRowElement>;
	profitFeesAmount: number | null;
};

export const summaryDefault: SummaryValue = {
	netProfitRef: { current: null },
	netProfitAmount: null,
	orderFeesRef: { current: null },
	orderFeesAmount: null,
	profitFeesRef: { current: null },
	profitFeesAmount: null,
};

export const useSummary = () => {
	const { filterState } = useGlobalAnalytics();
	const coverageFrom = coverageFromRange(filterState.range, "start");
	const coverageTo = coverageFromRange(filterState.range, "end");

	// Net profit
	const netProfitRef = useRef<HTMLTableRowElement>(null);
	const netProfitAmount = useSumValuesRange(
		moduleName,
		"net-profit-amount",
		coverageFrom,
		coverageTo,
		undefined,
		// useInView(netProfitRef), // Do not use inView! Others metrics composes these metrics.
	);

	// Order fees
	const orderFeesRef = useRef<HTMLTableRowElement>(null);
	const orderFeesAmount = useSumValuesRange(
		moduleName,
		"order-fees-and-taxes-amount",
		coverageFrom,
		coverageTo,
		undefined,
		useInView(orderFeesRef),
	);

	// Profit fees
	const profitFeesRef = useRef<HTMLTableRowElement>(null);
	const profitFeesAmount = useSumValuesRange(
		moduleName,
		"profit-fees-and-taxes-amount",
		coverageFrom,
		coverageTo,
		undefined,
		useInView(profitFeesRef),
	);

	return {
		netProfitRef,
		netProfitAmount:
			netProfitAmount.isLoading || netProfitAmount.error
				? null
				: netProfitAmount.data?.sum ?? 0.0,
		orderFeesRef,
		orderFeesAmount:
			orderFeesAmount.isLoading || orderFeesAmount.error
				? null
				: orderFeesAmount.data?.sum ?? 0.0,
		profitFeesRef,
		profitFeesAmount:
			profitFeesAmount.isLoading || profitFeesAmount.error
				? null
				: profitFeesAmount.data?.sum ?? 0.0,
	};
};
