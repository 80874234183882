"use client";

import { RefObject, useRef } from "react";
import { coverageFromRange } from "../cps/coverage-factory";
import { useSumValuesRange } from "../cps/range-hooks";
import { CoveragePosition } from "../cps/types";
import { useGlobalAnalytics } from "../store/context";
import { moduleName } from "./constants";
import { useInView } from "framer-motion";

export type SalesPerProductValue = {
	// Annual
	annualRef: RefObject<HTMLTableRowElement>;
	annualCount: number | null;

	// Semiannual
	semiannualRef: RefObject<HTMLTableRowElement>;
	semiannualCount: number | null;

	// Upgrade
	upgradeRef: RefObject<HTMLTableRowElement>;
	upgradeCount: number | null;

	// Complement
	complementRef: RefObject<HTMLTableRowElement>;
	complementCount: number | null;
};

export const salesPerProductDefault: SalesPerProductValue = {
	annualRef: { current: null },
	annualCount: null,
	semiannualRef: { current: null },
	semiannualCount: null,
	upgradeRef: { current: null },
	upgradeCount: null,
	complementRef: { current: null },
	complementCount: null,
};

const useSalesPerProductSum = (
	targetRef: RefObject<HTMLTableRowElement>,
	coverageFrom: CoveragePosition,
	coverageTo: CoveragePosition,
	product: string,
) => {
	const count = useSumValuesRange(
		moduleName,
		"sales-per-product-count",
		coverageFrom,
		coverageTo,
		{ product: [product] },
		// useInView(targetRef), // Do not use inView! Others metrics composes these metrics.
	);

	return count.isLoading || count.error ? null : count.data?.sum ?? 0.0;
};

export const useSalesPerProduct = () => {
	const { filterState } = useGlobalAnalytics();
	const from = coverageFromRange(filterState.range, "start");
	const to = coverageFromRange(filterState.range, "end");

	const annualRef = useRef<HTMLTableRowElement>(null);
	const semiannualRef = useRef<HTMLTableRowElement>(null);
	const upgradeRef = useRef<HTMLTableRowElement>(null);
	const complementRef = useRef<HTMLTableRowElement>(null);

	return {
		annualRef,
		annualCount: useSalesPerProductSum(annualRef, from, to, "annual"),
		semiannualRef,
		semiannualCount: useSalesPerProductSum(
			semiannualRef,
			from,
			to,
			"semiannual",
		),
		upgradeRef,
		upgradeCount: useSalesPerProductSum(upgradeRef, from, to, "upgrade"),
		complementRef,
		complementCount: useSalesPerProductSum(
			complementRef,
			from,
			to,
			"complement",
		),
	};
};
