import { Box, Chip, Paper, Typography, alpha, styled } from "@mui/material";
import { ReactNode } from "react";
import { InlineIcon } from "@iconify/react";

const Root = styled(Paper, {
	target: "GA_Widget",
	shouldForwardProp: (prop) => prop !== "disabled",
})<{
	disabled?: boolean;
}>(({ theme, disabled }) => ({
	position: "relative",
	overflow: "auto",

	"& + .GA_Widget": {
		marginTop: theme.spacing(1),
	},

	opacity: disabled ? 0.4 : 1,

	"&::after": {
		display: "none",
		content: "''",
		position: "absolute",
		top: 0,
		left: 0,
		width: "100%",
		height: "100%",
		zIndex: -1,
		backgroundImage:
			"radial-gradient(rgba(0, 0, 0, 0) 66%, rgba(255, 200, 173, 0.04))",
	},

	"@supports (pointer-events: none)": {
		"&::after": {
			display: "block",
			zIndex: 1,
			pointerEvents: "none",
			userSelect: "none",
		},
	},
}));

export function Block({
	title,
	icon,
	immediate,
	children,
}: {
	title: string;
	icon?: string;
	immediate?: true;
	children: ReactNode;
}) {
	return (
		<Root
			elevation={6}
			sx={{
				"& + &": {
					mt: 1,
				},
				py: 1.4,
				px: 1,
			}}
		>
			<Box
				sx={(them) => ({
					mx: 1,
					mb: 0.5,
					pb: 0.7,
					borderBottom: `1px solid ${alpha(them.palette.secondary.main, 0.05)}`,
				})}
			>
				<Typography
					variant="body1"
					color="primary.main"
					fontWeight={600}
					textAlign="left"
					lineHeight={1}
					sx={{
						display: "flex",
						alignItems: "center",
						justifyContent: "flex-start",
					}}
				>
					{icon && (
						<InlineIcon icon={icon} style={{ marginRight: 7, marginTop: -1 }} />
					)}
					<span>{title}</span>
					{immediate && (
						<Chip
							component="span"
							size="small"
							label="Hoje"
							color="secondary"
							style={{ marginLeft: 10, marginTop: -1 }}
						/>
					)}
				</Typography>
			</Box>
			{children}
		</Root>
	);
}

export function BlockHeader() {
	return null;
}
