"use client";

import { FetcherInterface } from "global-analytics";
import { fetch } from "cross-fetch";
import Queue from "p-queue";
import Cookie from "js-cookie";

const apiUrl = process.env.NEXT_PUBLIC_API_URL;

const fetchQueue = new Queue({
	autoStart: true,
	concurrency: 10,
	timeout: 10000,
	throwOnTimeout: true,
});

export const fetcher: FetcherInterface = async function ([route, body]) {
	const accessToken = Cookie.get("access_token");

	const headers = new globalThis.Headers();
	headers.append("Content-Type", "application/json");
	headers.append("Authorization", `Bearer ${accessToken}`);

	return fetchQueue.add(async () => {
		const response = await fetch(`${apiUrl}/v1/${route}`, {
			method: "POST",
			headers,
			body,
		});

		return (await response.json()).data;
	});
};
