"use client";

import { coverageFromRange } from "../cps/coverage-factory";
import { useSumValuesRange } from "../cps/range-hooks";
import { CoveragePosition } from "../cps/types";
import { useGlobalAnalytics } from "../store/context";
import { moduleName } from "./constants";

export type AccountsEngagedCountValue = {
	accountsEngagedCount: number | null;
};

export const accountsEngagedCountDefault: AccountsEngagedCountValue = {
	accountsEngagedCount: null,
};

const useAccountsEngagedSum = (
	coverageFrom: CoveragePosition,
	coverageTo: CoveragePosition,
) => {
	const count = useSumValuesRange(
		moduleName,
		"accounts-engaged-count",
		coverageFrom,
		coverageTo,
	);

	return count.isLoading || count.error ? null : count.data?.sum ?? 0.0;
};

export const useAccountsEngagedCount = () => {
	const { filterState } = useGlobalAnalytics();
	const from = coverageFromRange(filterState.range, "start");
	const to = coverageFromRange(filterState.range, "end");

	return {
		accountsEngagedCount: useAccountsEngagedSum(from, to),
	};
};
