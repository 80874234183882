import {
	Grid,
	Table,
	TableHead,
	TableBody,
	TableCell,
	TableRow,
} from "@mui/material";
import Widget from "../../../../components/Widget";
import { Block } from "../../../../components/Block";
import { decimal, decimalPercent, useSupportCpsModule } from "global-analytics";
import { Spinner } from "../../../../components/Spinner";
import { ReactNode, RefObject } from "react";

function TotalPercentTableRow({
	preCount,
	postCount,
}: {
	preCount: number | null;
	postCount: number | null;
}) {
	let prePercent: string | null = null;
	let postPercent: string | null = null;

	if (typeof preCount === "number" && typeof postCount === "number") {
		const total = preCount + postCount;

		if (total === 0.0) {
			prePercent = "0.0%";
			postPercent = "0,0%";
		} else {
			prePercent = decimalPercent(preCount / total);
			postPercent = decimalPercent(postCount / total);
		}
	}

	return (
		<TableRow>
			<TableCell variant="head">%</TableCell>
			<TableCell variant="head">
				{typeof prePercent === "string" ? prePercent : <Spinner />}
			</TableCell>
			<TableCell variant="head">
				{typeof postPercent === "string" ? postPercent : <Spinner />}
			</TableCell>
			<TableCell variant="head" />
		</TableRow>
	);
}

function TotalCountTableRow({
	name,
	preCount,
	postCount,
}: {
	name: ReactNode;
	preCount: number | null;
	postCount: number | null;
}) {
	return (
		<TableRow>
			<TableCell variant="head">{name}</TableCell>
			<TableCell variant="head">
				{typeof preCount === "number" ? decimal.format(preCount) : <Spinner />}
			</TableCell>
			<TableCell variant="head">
				{typeof postCount === "number" ? (
					decimal.format(postCount)
				) : (
					<Spinner />
				)}
			</TableCell>
			<TableCell variant="head">
				{typeof preCount === "number" && typeof postCount === "number" ? (
					decimal.format(preCount + postCount)
				) : (
					<Spinner />
				)}
			</TableCell>
		</TableRow>
	);
}

function VariantTableRow({
	name,
	preRef,
	preCount,
	postRef,
	postCount,
}: {
	name: ReactNode;
	preRef?: RefObject<HTMLTableRowElement>;
	preCount: number | null;
	postRef?: RefObject<HTMLTableRowElement>;
	postCount: number | null;
}) {
	return (
		<TableRow>
			<TableCell>{name}</TableCell>
			<TableCell ref={preRef}>
				{typeof preCount === "number" ? decimal.format(preCount) : <Spinner />}
			</TableCell>
			<TableCell ref={postRef}>
				{typeof postCount === "number" ? (
					decimal.format(postCount)
				) : (
					<Spinner />
				)}
			</TableCell>
			<TableCell>
				{typeof preCount === "number" && typeof postCount === "number" ? (
					decimal.format(preCount + postCount)
				) : (
					<Spinner />
				)}
			</TableCell>
		</TableRow>
	);
}

function OperatorsWidget() {
	const {
		arthurPreCount,
		arthurPreRef,
		arthurPostCount,
		arthurPostRef,
		alanaPreCount,
		alanaPreRef,
		alanaPostCount,
		alanaPostRef,
		renanPreCount,
		renanPreRef,
		renanPostCount,
		renanPostRef,
		amandaPreCount,
		amandaPreRef,
		amandaPostCount,
		amandaPostRef,
	} = useSupportCpsModule();

	const totalPreCount =
		typeof arthurPreCount === "number" &&
		typeof alanaPreCount === "number" &&
		typeof renanPreCount === "number" &&
		typeof amandaPreCount === "number"
			? arthurPreCount + alanaPreCount + renanPreCount + amandaPreCount
			: null;

	const totalPostCount =
		typeof arthurPostCount === "number" &&
		typeof alanaPostCount === "number" &&
		typeof renanPostCount === "number" &&
		typeof amandaPostCount === "number"
			? arthurPostCount + alanaPostCount + renanPostCount + amandaPostCount
			: null;

	return (
		<Widget title="Atendentes">
			<Table size="small">
				<TableHead>
					<TableRow>
						<TableCell></TableCell>
						<TableCell>Pré</TableCell>
						<TableCell>Pós</TableCell>
						<TableCell>Total</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					<TotalPercentTableRow
						preCount={totalPreCount}
						postCount={totalPostCount}
					/>
					<TotalCountTableRow
						name="Total"
						preCount={totalPreCount}
						postCount={totalPostCount}
					/>
					<VariantTableRow
						name="Arthur"
						preRef={arthurPreRef}
						preCount={arthurPreCount}
						postRef={arthurPostRef}
						postCount={arthurPostCount}
					/>
					<VariantTableRow
						name="Alana"
						preRef={alanaPreRef}
						preCount={alanaPreCount}
						postRef={alanaPostRef}
						postCount={alanaPostCount}
					/>
					<VariantTableRow
						name="Renan"
						preRef={renanPreRef}
						preCount={renanPreCount}
						postRef={renanPostRef}
						postCount={renanPostCount}
					/>
					<VariantTableRow
						name="Amanda"
						preRef={amandaPreRef}
						preCount={amandaPreCount}
						postRef={amandaPostRef}
						postCount={amandaPostCount}
					/>
				</TableBody>
			</Table>
		</Widget>
	);
}

function CategoriesWidget() {
	const {
		medBrPreRef,
		medBrPreCount,
		medBrPostRef,
		medBrPostCount,
		medExtPreRef,
		medExtPreCount,
		medExtPostRef,
		medExtPostCount,
		pdPreRef,
		pdPreCount,
		pdPostRef,
		pdPostCount,
		eocPreRef,
		eocPreCount,
		eocPostRef,
		eocPostCount,
		pdMedBrPreRef,
		pdMedBrPreCount,
		pdMedBrPostRef,
		pdMedBrPostCount,
		pdMedExtPreRef,
		pdMedExtPreCount,
		pdMedExtPostRef,
		pdMedExtPostCount,
		othersPreRef,
		othersPreCount,
		othersPostRef,
		othersPostCount,
	} = useSupportCpsModule();

	const totalPreCount =
		typeof medBrPreCount === "number" &&
		typeof medExtPreCount === "number" &&
		typeof pdPreCount === "number" &&
		typeof eocPreCount === "number" &&
		typeof pdMedBrPreCount === "number" &&
		typeof pdMedExtPreCount === "number" &&
		typeof othersPreCount === "number"
			? medBrPreCount +
			  medExtPreCount +
			  pdPreCount +
			  eocPreCount +
			  pdMedBrPreCount +
			  pdMedExtPreCount +
			  othersPreCount
			: null;

	const totalPostCount =
		typeof medBrPostCount === "number" &&
		typeof medExtPostCount === "number" &&
		typeof pdPostCount === "number" &&
		typeof eocPostCount === "number" &&
		typeof pdMedBrPostCount === "number" &&
		typeof pdMedExtPostCount === "number" &&
		typeof othersPostCount === "number"
			? medBrPostCount +
			  medExtPostCount +
			  pdPostCount +
			  eocPostCount +
			  pdMedBrPostCount +
			  pdMedExtPostCount +
			  othersPostCount
			: null;

	return (
		<Widget title="Categorias">
			<Table size="small">
				<TableHead>
					<TableRow>
						<TableCell></TableCell>
						<TableCell>Pré</TableCell>
						<TableCell>Pós</TableCell>
						<TableCell>Total</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					<TotalPercentTableRow
						preCount={totalPreCount}
						postCount={totalPostCount}
					/>
					<TotalCountTableRow
						name="Total"
						preCount={totalPreCount}
						postCount={totalPostCount}
					/>
					<VariantTableRow
						name="BR"
						preRef={medBrPreRef}
						preCount={medBrPreCount}
						postRef={medBrPostRef}
						postCount={medBrPostCount}
					/>
					<VariantTableRow
						name="EXT"
						preRef={medExtPreRef}
						preCount={medExtPreCount}
						postRef={medExtPostRef}
						postCount={medExtPostCount}
					/>
					<VariantTableRow
						name="PD"
						preRef={pdPreRef}
						preCount={pdPreCount}
						postRef={pdPostRef}
						postCount={pdPostCount}
					/>
					<VariantTableRow
						name="EOC"
						preRef={eocPreRef}
						preCount={eocPreCount}
						postRef={eocPostRef}
						postCount={eocPostCount}
					/>
					<VariantTableRow
						name="PD + BR"
						preRef={pdMedBrPreRef}
						preCount={pdMedBrPreCount}
						postRef={pdMedBrPostRef}
						postCount={pdMedBrPostCount}
					/>
					<VariantTableRow
						name="PD + EXT"
						preRef={pdMedExtPreRef}
						preCount={pdMedExtPreCount}
						postRef={pdMedExtPostRef}
						postCount={pdMedExtPostCount}
					/>
					<VariantTableRow
						name="Outros"
						preRef={othersPreRef}
						preCount={othersPreCount}
						postRef={othersPostRef}
						postCount={othersPostCount}
					/>
				</TableBody>
			</Table>
		</Widget>
	);
}

export function SupportBlock() {
	return (
		<Block title="Atendimentos" icon="ic:outline-message">
			<Grid container spacing={1}>
				<Grid item xs={6}>
					<OperatorsWidget />
				</Grid>
				<Grid item xs={6}>
					<CategoriesWidget />
				</Grid>
			</Grid>
		</Block>
	);
}
