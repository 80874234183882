export { GlobalAnalytics } from "./lib/global-analytics";
export type { GlobalAnalyticsProps } from "./lib/global-analytics";
export type { GlobalAnalyticsOptions } from "./lib/contracts/global-analytics.interface";
export type { FetcherInterface } from "./lib/contracts/fetcher.interface";
export {
	GlobalAnalyticsContext,
	useGlobalAnalytics,
} from "./lib/store/context";
export {
	useAvgRealtimeRange,
	useAvgValuesRange,
	useGroupAvgRealtimeRange,
	useGroupAvgValuesRange,
	useGroupSumRealtimeRange,
	useGroupSumValuesRange,
	useSumRealtimeRange,
	useSumValuesRange,
} from "./lib/cps/range-hooks";
export { coverageFromRange } from "./lib/cps/coverage-factory";
export type { CoveragePosition } from "./lib/cps/types";
export type { FilterState } from "./lib/contracts/filters";
export { BRL, decimal, decimalPercent } from "./lib/utils/format";

// Implementations
export {
	CommCpsModuleProvider,
	useCommCpsModule,
} from "./lib/comm/comm.cps-module";
export {
	LmsCpsModuleProvider,
	useLmsCpsModule,
} from "./lib/lms/lms.cps-module";
export {
	SupportCpsModuleProvider,
	useSupportCpsModule,
} from "./lib/support/support.cps-module";
export {
	FunnelCpsModuleProvider,
	useFunnelCpsModule,
} from "./lib/funnel/funnel.cps-module";
export {
	MarketingCpsModuleProvider,
	useMarketingCpsModule,
} from "./lib/marketing/marketing.cps-module";
export {
	PagesCpsModuleProvider,
	usePagesCpsModule,
} from "./lib/pages/pages.cps-module";
export {
	SocialCpsModuleProvider,
	useSocialCpsModule, //
} from "./lib/social/social.cps-module";
