"use client";

import { coverageFromRange } from "../cps/coverage-factory";
import { useSumValuesRange } from "../cps/range-hooks";
import { CoveragePosition } from "../cps/types";
import { useGlobalAnalytics } from "../store/context";
import { moduleName } from "./constants";

export type BioClicksCountValue = {
	bioClicksCount: number | null;
};

export const bioClicksCountDefault: BioClicksCountValue = {
	bioClicksCount: null,
};

const useBioClicksSum = (
	coverageFrom: CoveragePosition,
	coverageTo: CoveragePosition,
) => {
	const count = useSumValuesRange(
		moduleName,
		"bio-clicks-count",
		coverageFrom,
		coverageTo,
	);

	return count.isLoading || count.error ? null : count.data?.sum ?? 0.0;
};

export const useBioClicksCount = () => {
	const { filterState } = useGlobalAnalytics();
	const from = coverageFromRange(filterState.range, "start");
	const to = coverageFromRange(filterState.range, "end");

	return {
		bioClicksCount: useBioClicksSum(from, to),
	};
};
