"use client";

import { useCpsActionHook } from "./cps-action-hook";
import { CoveragePosition, MetricParams } from "./types";

type SumResponse = { sum: number | null };
type GroupSumResponse = {
	instances: {
		_id: string;
		sum: number | null;
	}[];
};

type AvgResponse = { avg: number | null };
type GroupAvgResponse = {
	instances: {
		_id: string;
		sum: number | null;
	}[];
};

export const useSumRealtimeRange = (
	moduleName: string,
	metric: string,
	coverageFrom: Partial<CoveragePosition>,
	coverageTo: Partial<CoveragePosition>,
	params?: MetricParams,
	inView?: boolean,
) =>
	useCpsActionHook<SumResponse>(
		moduleName,
		"sum-realtime-range",
		{
			metric,
			coverageFrom,
			coverageTo,
			params,
		},
		inView,
	);

export const useSumValuesRange = (
	moduleName: string,
	metric: string,
	coverageFrom: Partial<CoveragePosition>,
	coverageTo: Partial<CoveragePosition>,
	params?: MetricParams,
	inView?: boolean,
) =>
	useCpsActionHook<SumResponse>(
		moduleName,
		"sum-values-range",
		{
			metric,
			coverageFrom,
			coverageTo,
			params,
		},
		inView,
	);

export const useAvgRealtimeRange = (
	moduleName: string,
	metric: string,
	coverageFrom: Partial<CoveragePosition>,
	coverageTo: Partial<CoveragePosition>,
	params?: MetricParams,
	inView?: boolean,
) =>
	useCpsActionHook<AvgResponse>(
		moduleName,
		"avg-realtime-range",
		{
			metric,
			coverageFrom,
			coverageTo,
			params,
		},
		inView,
	);

export const useAvgValuesRange = (
	moduleName: string,
	metric: string,
	coverageFrom: Partial<CoveragePosition>,
	coverageTo: Partial<CoveragePosition>,
	params?: MetricParams,
	inView?: boolean,
) =>
	useCpsActionHook<AvgResponse>(
		moduleName,
		"avg-values-range",
		{
			metric,
			coverageFrom,
			coverageTo,
			params,
		},
		inView,
	);

export const useGroupSumRealtimeRange = (
	moduleName: string,
	metric: string,
	coverageFrom: Partial<CoveragePosition>,
	coverageTo: Partial<CoveragePosition>,
	params?: MetricParams,
	inView?: boolean,
) =>
	useCpsActionHook<GroupSumResponse[]>(
		moduleName,
		"group-sum-realtime-range",
		{
			metric,
			coverageFrom,
			coverageTo,
			params,
		},
		inView,
	);

export const useGroupSumValuesRange = (
	moduleName: string,
	metric: string,
	coverageFrom: Partial<CoveragePosition>,
	coverageTo: Partial<CoveragePosition>,
	params?: MetricParams,
	inView?: boolean,
) =>
	useCpsActionHook<GroupSumResponse[]>(
		moduleName,
		"group-sum-values-range",
		{
			metric,
			coverageFrom,
			coverageTo,
			params,
		},
		inView,
	);

export const useGroupAvgRealtimeRange = (
	moduleName: string,
	metric: string,
	coverageFrom: Partial<CoveragePosition>,
	coverageTo: Partial<CoveragePosition>,
	params?: MetricParams,
	inView?: boolean,
) =>
	useCpsActionHook<GroupAvgResponse[]>(
		moduleName,
		"group-avg-realtime-range",
		{
			metric,
			coverageFrom,
			coverageTo,
			params,
		},
		inView,
	);

export const useGroupAvgValuesRange = (
	moduleName: string,
	metric: string,
	coverageFrom: Partial<CoveragePosition>,
	coverageTo: Partial<CoveragePosition>,
	params?: MetricParams,
	inView?: boolean,
) =>
	useCpsActionHook<GroupAvgResponse[]>(
		moduleName,
		"group-avg-values-range",
		{
			metric,
			coverageFrom,
			coverageTo,
			params,
		},
		inView,
	);
