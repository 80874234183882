"use client";

import { ReactNode, createContext, useContext } from "react";
import {
	SpendPerTagValue,
	spendPerTagDefault,
	useSpendPerTag,
} from "./spend-per-tag";
import {
	conversionsDefault,
	ConversionsValue,
	useConversions,
} from "./conversions";

type MarketingCpsModuleContextValue = SpendPerTagValue & ConversionsValue;

const MarketingCpsModuleContext = createContext<MarketingCpsModuleContextValue>(
	{
		...spendPerTagDefault,
		...conversionsDefault,
	},
);

export function MarketingCpsModuleProvider({
	children,
}: {
	children: ReactNode;
}) {
	const spendPerTag = useSpendPerTag();
	const conversions = useConversions();

	return (
		<MarketingCpsModuleContext.Provider
			value={{
				...spendPerTag,
				...conversions,
			}}
		>
			{children}
		</MarketingCpsModuleContext.Provider>
	);
}

export function useMarketingCpsModule() {
	return useContext(MarketingCpsModuleContext);
}
