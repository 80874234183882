"use client";

import { RefObject, useRef } from "react";
import { coverageFromRange } from "../cps/coverage-factory";
import { useSumValuesRange } from "../cps/range-hooks";
import { CoveragePosition } from "../cps/types";
import { useGlobalAnalytics } from "../store/context";
import { moduleName } from "./constants";
import { useInView } from "framer-motion";

export type RenewalsValue = {
	annualRenewalsRef: RefObject<HTMLTableRowElement>;
	annualRenewalsCount: number | null;
	semiannualRenewalsRef: RefObject<HTMLTableRowElement>;
	semiannualRenewalsCount: number | null;
};

export const renewalsDefault: RenewalsValue = {
	annualRenewalsRef: { current: null },
	annualRenewalsCount: null,
	semiannualRenewalsRef: { current: null },
	semiannualRenewalsCount: null,
};

const useRenewalsSum = (
	targetRef: RefObject<HTMLTableRowElement>,
	coverageFrom: CoveragePosition,
	coverageTo: CoveragePosition,
	product: string,
) => {
	const count = useSumValuesRange(
		moduleName,
		"renewals-count",
		coverageFrom,
		coverageTo,
		{ product: [product] },
		useInView(targetRef),
	);

	return count.isLoading || count.error ? null : count.data?.sum ?? 0.0;
};

export const useRenewals = () => {
	const { filterState } = useGlobalAnalytics();
	const from = coverageFromRange(filterState.range, "start");
	const to = coverageFromRange(filterState.range, "end");

	const annualRenewalsRef = useRef<HTMLTableRowElement>(null);
	const semiannualRenewalsRef = useRef<HTMLTableRowElement>(null);

	return {
		annualRenewalsRef,
		annualRenewalsCount: useRenewalsSum(annualRenewalsRef, from, to, "annual"),
		semiannualRenewalsRef,
		semiannualRenewalsCount: useRenewalsSum(
			semiannualRenewalsRef,
			from,
			to,
			"semiannual",
		),
	};
};
