"use client";

import { coverageFromRange } from "../cps/coverage-factory";
import { useSumValuesRange } from "../cps/range-hooks";
import { CoveragePosition } from "../cps/types";
import { useGlobalAnalytics } from "../store/context";
import { moduleName } from "./constants";

export type ProfileViewsCountValue = {
	profileViewsCount: number | null;
};

export const profileViewsCountDefault: ProfileViewsCountValue = {
	profileViewsCount: null,
};

const useProfileViewsSum = (
	coverageFrom: CoveragePosition,
	coverageTo: CoveragePosition,
) => {
	const count = useSumValuesRange(
		moduleName,
		"profile-views-count",
		coverageFrom,
		coverageTo,
	);

	return count.isLoading || count.error ? null : count.data?.sum ?? 0.0;
};

export const useProfileViewsCount = () => {
	const { filterState } = useGlobalAnalytics();
	const from = coverageFromRange(filterState.range, "start");
	const to = coverageFromRange(filterState.range, "end");

	return {
		profileViewsCount: useProfileViewsSum(from, to),
	};
};
