import {
	Grid,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
} from "@mui/material";
import Widget from "../../../../components/Widget";
import { Block } from "../../../../components/Block";
import { decimal, usePagesCpsModule } from "global-analytics";
import { Spinner } from "../../../../components/Spinner";

export function PagesBlock() {
	const {
		homeMedBrVisitsCount,
		homeMedExtVisitsCount,
		homePdVisitsCount,
		homeEocVisitsCount,
		homePdMedBrVisitsCount,
		homePdMedExtVisitsCount,
		homeOthersVisitsCount,
		//
		universitiesMedBrVisitsCount,
		universitiesMedExtVisitsCount,
		universitiesPdVisitsCount,
		universitiesEocVisitsCount,
		universitiesPdMedBrVisitsCount,
		universitiesPdMedExtVisitsCount,
		universitiesOthersVisitsCount,
		//
		trainingMedBrVisitsCount,
		trainingMedExtVisitsCount,
		trainingPdVisitsCount,
		trainingEocVisitsCount,
		trainingPdMedBrVisitsCount,
		trainingPdMedExtVisitsCount,
		trainingOthersVisitsCount,
		//
		salesMedBrVisitsCount,
		salesMedExtVisitsCount,
		salesPdVisitsCount,
		salesEocVisitsCount,
		salesPdMedBrVisitsCount,
		salesPdMedExtVisitsCount,
		salesOthersVisitsCount,
	} = usePagesCpsModule();

	const medBrTotal =
		typeof homeMedBrVisitsCount === "number" &&
		typeof universitiesMedBrVisitsCount === "number" &&
		typeof trainingMedBrVisitsCount === "number" &&
		typeof salesMedBrVisitsCount === "number"
			? homeMedBrVisitsCount +
				universitiesMedBrVisitsCount +
				trainingMedBrVisitsCount +
				salesMedBrVisitsCount
			: null;

	const medExtTotal =
		typeof homeMedExtVisitsCount === "number" &&
		typeof universitiesMedExtVisitsCount === "number" &&
		typeof trainingMedExtVisitsCount === "number" &&
		typeof salesMedExtVisitsCount === "number"
			? homeMedExtVisitsCount +
				universitiesMedExtVisitsCount +
				trainingMedExtVisitsCount +
				salesMedExtVisitsCount
			: null;

	const pdTotal =
		typeof homePdVisitsCount === "number" &&
		typeof universitiesPdVisitsCount === "number" &&
		typeof trainingPdVisitsCount === "number" &&
		typeof salesPdVisitsCount === "number"
			? homePdVisitsCount +
				universitiesPdVisitsCount +
				trainingPdVisitsCount +
				salesPdVisitsCount
			: null;

	const eocTotal =
		typeof homeEocVisitsCount === "number" &&
		typeof universitiesEocVisitsCount === "number" &&
		typeof trainingEocVisitsCount === "number" &&
		typeof salesEocVisitsCount === "number"
			? homeEocVisitsCount +
				universitiesEocVisitsCount +
				trainingEocVisitsCount +
				salesEocVisitsCount
			: null;

	const pdMedBrTotal =
		typeof homePdMedBrVisitsCount === "number" &&
		typeof universitiesPdMedBrVisitsCount === "number" &&
		typeof trainingPdMedBrVisitsCount === "number" &&
		typeof salesPdMedBrVisitsCount === "number"
			? homePdMedBrVisitsCount +
				universitiesPdMedBrVisitsCount +
				trainingPdMedBrVisitsCount +
				salesPdMedBrVisitsCount
			: null;

	const pdMedExtTotal =
		typeof homePdMedExtVisitsCount === "number" &&
		typeof universitiesPdMedExtVisitsCount === "number" &&
		typeof trainingPdMedExtVisitsCount === "number" &&
		typeof salesPdMedExtVisitsCount === "number"
			? homePdMedExtVisitsCount +
				universitiesPdMedExtVisitsCount +
				trainingPdMedExtVisitsCount +
				salesPdMedExtVisitsCount
			: null;

	const othersTotal =
		typeof homeOthersVisitsCount === "number" &&
		typeof universitiesOthersVisitsCount === "number" &&
		typeof trainingOthersVisitsCount === "number" &&
		typeof salesOthersVisitsCount === "number"
			? homeOthersVisitsCount +
				universitiesOthersVisitsCount +
				trainingOthersVisitsCount +
				salesOthersVisitsCount
			: null;

	// Overall total

	const homeTotal =
		typeof homeMedBrVisitsCount === "number" &&
		typeof homeMedExtVisitsCount === "number" &&
		typeof homePdVisitsCount === "number" &&
		typeof homeEocVisitsCount === "number" &&
		typeof homePdMedBrVisitsCount === "number" &&
		typeof homePdMedExtVisitsCount === "number" &&
		typeof homeOthersVisitsCount === "number"
			? homeMedBrVisitsCount +
				homeMedExtVisitsCount +
				homePdVisitsCount +
				homeEocVisitsCount +
				homePdMedBrVisitsCount +
				homePdMedExtVisitsCount +
				homeOthersVisitsCount
			: null;

	const universitiesTotal =
		typeof universitiesMedBrVisitsCount === "number" &&
		typeof universitiesMedExtVisitsCount === "number" &&
		typeof universitiesPdVisitsCount === "number" &&
		typeof universitiesEocVisitsCount === "number" &&
		typeof universitiesPdMedBrVisitsCount === "number" &&
		typeof universitiesPdMedExtVisitsCount === "number" &&
		typeof universitiesOthersVisitsCount === "number"
			? universitiesMedBrVisitsCount +
				universitiesMedExtVisitsCount +
				universitiesPdVisitsCount +
				universitiesEocVisitsCount +
				universitiesPdMedBrVisitsCount +
				universitiesPdMedExtVisitsCount +
				universitiesOthersVisitsCount
			: null;

	const trainingTotal =
		typeof trainingMedBrVisitsCount === "number" &&
		typeof trainingMedExtVisitsCount === "number" &&
		typeof trainingPdVisitsCount === "number" &&
		typeof trainingEocVisitsCount === "number" &&
		typeof trainingPdMedBrVisitsCount === "number" &&
		typeof trainingPdMedExtVisitsCount === "number" &&
		typeof trainingOthersVisitsCount === "number"
			? trainingMedBrVisitsCount +
				trainingMedExtVisitsCount +
				trainingPdVisitsCount +
				trainingEocVisitsCount +
				trainingPdMedBrVisitsCount +
				trainingPdMedExtVisitsCount +
				trainingOthersVisitsCount
			: null;

	const salesTotal =
		typeof salesMedBrVisitsCount === "number" &&
		typeof salesMedExtVisitsCount === "number" &&
		typeof salesPdVisitsCount === "number" &&
		typeof salesEocVisitsCount === "number" &&
		typeof salesPdMedBrVisitsCount === "number" &&
		typeof salesPdMedExtVisitsCount === "number" &&
		typeof salesOthersVisitsCount === "number"
			? salesMedBrVisitsCount +
				salesMedExtVisitsCount +
				salesPdVisitsCount +
				salesEocVisitsCount +
				salesPdMedBrVisitsCount +
				salesPdMedExtVisitsCount +
				salesOthersVisitsCount
			: null;

	const categoriesTotal =
		typeof medBrTotal === "number" &&
		typeof medExtTotal === "number" &&
		typeof pdTotal === "number" &&
		typeof eocTotal === "number" &&
		typeof pdMedBrTotal === "number" &&
		typeof pdMedExtTotal === "number" &&
		typeof othersTotal === "number"
			? medBrTotal +
				medExtTotal +
				pdTotal +
				eocTotal +
				pdMedBrTotal +
				pdMedExtTotal +
				othersTotal
			: null;

	return (
		<Block title="Páginas" icon="icon-park-twotone:browser">
			<Grid container spacing={1}>
				<Grid item xs={12}>
					<Widget>
						<Table size="small">
							<TableHead>
								<TableRow>
									<TableCell />
									<TableCell>Inicial</TableCell>
									<TableCell>Facul</TableCell>
									<TableCell>Treinam</TableCell>
									<TableCell>Vendas</TableCell>
									<TableCell>Total</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								<TableRow>
									<TableCell>BR</TableCell>
									<TableCell>
										{typeof homeMedBrVisitsCount === "number" ? (
											decimal.format(homeMedBrVisitsCount)
										) : (
											<Spinner />
										)}
									</TableCell>
									<TableCell>
										{typeof universitiesMedBrVisitsCount === "number" ? (
											decimal.format(universitiesMedBrVisitsCount)
										) : (
											<Spinner />
										)}
									</TableCell>
									<TableCell>
										{typeof trainingMedBrVisitsCount === "number" ? (
											decimal.format(trainingMedBrVisitsCount)
										) : (
											<Spinner />
										)}
									</TableCell>
									<TableCell>
										{typeof salesMedBrVisitsCount === "number" ? (
											decimal.format(salesMedBrVisitsCount)
										) : (
											<Spinner />
										)}
									</TableCell>
									<TableCell>
										{typeof medBrTotal === "number" ? (
											decimal.format(medBrTotal)
										) : (
											<Spinner />
										)}
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell>EXT</TableCell>
									<TableCell>
										{typeof homeMedExtVisitsCount === "number" ? (
											decimal.format(homeMedExtVisitsCount)
										) : (
											<Spinner />
										)}
									</TableCell>
									<TableCell>
										{typeof universitiesMedExtVisitsCount === "number" ? (
											decimal.format(universitiesMedExtVisitsCount)
										) : (
											<Spinner />
										)}
									</TableCell>
									<TableCell>
										{typeof trainingMedExtVisitsCount === "number" ? (
											decimal.format(trainingMedExtVisitsCount)
										) : (
											<Spinner />
										)}
									</TableCell>
									<TableCell>
										{typeof salesMedExtVisitsCount === "number" ? (
											decimal.format(salesMedExtVisitsCount)
										) : (
											<Spinner />
										)}
									</TableCell>
									<TableCell>
										{typeof medExtTotal === "number" ? (
											decimal.format(medExtTotal)
										) : (
											<Spinner />
										)}
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell>PD</TableCell>
									<TableCell>
										{typeof homePdVisitsCount === "number" ? (
											decimal.format(homePdVisitsCount)
										) : (
											<Spinner />
										)}
									</TableCell>
									<TableCell>
										{typeof universitiesPdVisitsCount === "number" ? (
											decimal.format(universitiesPdVisitsCount)
										) : (
											<Spinner />
										)}
									</TableCell>
									<TableCell>
										{typeof trainingPdVisitsCount === "number" ? (
											decimal.format(trainingPdVisitsCount)
										) : (
											<Spinner />
										)}
									</TableCell>

									<TableCell>
										{typeof salesPdVisitsCount === "number" ? (
											decimal.format(salesPdVisitsCount)
										) : (
											<Spinner />
										)}
									</TableCell>
									<TableCell>
										{typeof pdTotal === "number" ? (
											decimal.format(pdTotal)
										) : (
											<Spinner />
										)}
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell>EOC</TableCell>
									<TableCell>
										{typeof homeEocVisitsCount === "number" ? (
											decimal.format(homeEocVisitsCount)
										) : (
											<Spinner />
										)}
									</TableCell>
									<TableCell>
										{typeof universitiesEocVisitsCount === "number" ? (
											decimal.format(universitiesEocVisitsCount)
										) : (
											<Spinner />
										)}
									</TableCell>
									<TableCell>
										{typeof trainingEocVisitsCount === "number" ? (
											decimal.format(trainingEocVisitsCount)
										) : (
											<Spinner />
										)}
									</TableCell>
									<TableCell>
										{typeof salesEocVisitsCount === "number" ? (
											decimal.format(salesEocVisitsCount)
										) : (
											<Spinner />
										)}
									</TableCell>
									<TableCell>
										{typeof eocTotal === "number" ? (
											decimal.format(eocTotal)
										) : (
											<Spinner />
										)}
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell>PD + BR</TableCell>
									<TableCell>
										{typeof homePdMedBrVisitsCount === "number" ? (
											decimal.format(homePdMedBrVisitsCount)
										) : (
											<Spinner />
										)}
									</TableCell>
									<TableCell>
										{typeof universitiesPdMedBrVisitsCount === "number" ? (
											decimal.format(universitiesPdMedBrVisitsCount)
										) : (
											<Spinner />
										)}
									</TableCell>
									<TableCell>
										{typeof trainingPdMedBrVisitsCount === "number" ? (
											decimal.format(trainingPdMedBrVisitsCount)
										) : (
											<Spinner />
										)}
									</TableCell>
									<TableCell>
										{typeof salesPdMedBrVisitsCount === "number" ? (
											decimal.format(salesPdMedBrVisitsCount)
										) : (
											<Spinner />
										)}
									</TableCell>
									<TableCell>
										{typeof pdMedBrTotal === "number" ? (
											decimal.format(pdMedBrTotal)
										) : (
											<Spinner />
										)}
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell>PD + EXT</TableCell>
									<TableCell>
										{typeof homePdMedExtVisitsCount === "number" ? (
											decimal.format(homePdMedExtVisitsCount)
										) : (
											<Spinner />
										)}
									</TableCell>
									<TableCell>
										{typeof universitiesPdMedExtVisitsCount === "number" ? (
											decimal.format(universitiesPdMedExtVisitsCount)
										) : (
											<Spinner />
										)}
									</TableCell>
									<TableCell>
										{typeof trainingPdMedExtVisitsCount === "number" ? (
											decimal.format(trainingPdMedExtVisitsCount)
										) : (
											<Spinner />
										)}
									</TableCell>
									<TableCell>
										{typeof salesPdMedExtVisitsCount === "number" ? (
											decimal.format(salesPdMedExtVisitsCount)
										) : (
											<Spinner />
										)}
									</TableCell>
									<TableCell>
										{typeof pdMedExtTotal === "number" ? (
											decimal.format(pdMedExtTotal)
										) : (
											<Spinner />
										)}
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell>Outros</TableCell>
									<TableCell>
										{typeof homeOthersVisitsCount === "number" ? (
											decimal.format(homeOthersVisitsCount)
										) : (
											<Spinner />
										)}
									</TableCell>
									<TableCell>
										{typeof universitiesOthersVisitsCount === "number" ? (
											decimal.format(universitiesOthersVisitsCount)
										) : (
											<Spinner />
										)}
									</TableCell>
									<TableCell>
										{typeof trainingOthersVisitsCount === "number" ? (
											decimal.format(trainingOthersVisitsCount)
										) : (
											<Spinner />
										)}
									</TableCell>
									<TableCell>
										{typeof salesOthersVisitsCount === "number" ? (
											decimal.format(salesOthersVisitsCount)
										) : (
											<Spinner />
										)}
									</TableCell>
									<TableCell>
										{typeof othersTotal === "number" ? (
											decimal.format(othersTotal)
										) : (
											<Spinner />
										)}
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell>Total</TableCell>
									<TableCell>
										{typeof homeTotal === "number" ? (
											decimal.format(homeTotal)
										) : (
											<Spinner />
										)}
									</TableCell>
									<TableCell>
										{typeof universitiesTotal === "number" ? (
											decimal.format(universitiesTotal)
										) : (
											<Spinner />
										)}
									</TableCell>
									<TableCell>
										{typeof trainingTotal === "number" ? (
											decimal.format(trainingTotal)
										) : (
											<Spinner />
										)}
									</TableCell>
									<TableCell>
										{typeof salesTotal === "number" ? (
											decimal.format(salesTotal)
										) : (
											<Spinner />
										)}
									</TableCell>
									<TableCell>
										{typeof categoriesTotal === "number" ? (
											decimal.format(categoriesTotal)
										) : (
											<Spinner />
										)}
									</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					</Widget>
				</Grid>
			</Grid>
		</Block>
	);
}
