import { TableCell, TableRow } from "@mui/material";
import { decimalPercent, useCommCpsModule } from "global-analytics";
import { Spinner } from "../../../../../components/Spinner";

export function CreditCardPercentRow() {
	const { creditCardPercent, creditCardRef } = useCommCpsModule();

	return (
		<TableRow ref={creditCardRef}>
			<TableCell align="left">Cartão de Crédito</TableCell>
			{typeof creditCardPercent === "number" ? (
				<TableCell align="right">{decimalPercent(creditCardPercent)}</TableCell>
			) : (
				<TableCell align="right">
					<Spinner />
				</TableCell>
			)}
		</TableRow>
	);
}
