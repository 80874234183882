"use client";

import { ReactNode, createContext, useContext } from "react";
import {
	StagesPerCategoryValue,
	stagesPerCategoryDefault,
	useStagesPerCategory,
} from "./stages-per-category";
import {
	InactivationsPerAudienceValue,
	inactivationsPerAudienceDefault,
	useInactivationsPerAudience,
} from "./inactivations-per-audience";

type FunnelCpsModuleContextValue = StagesPerCategoryValue &
	InactivationsPerAudienceValue;

const FunnelCpsModuleContext = createContext<FunnelCpsModuleContextValue>({
	...stagesPerCategoryDefault,
	...inactivationsPerAudienceDefault,
});

export function FunnelCpsModuleProvider({ children }: { children: ReactNode }) {
	const stagesPerCategory = useStagesPerCategory();
	const inactivationsPerAudience = useInactivationsPerAudience();

	return (
		<FunnelCpsModuleContext.Provider
			value={{
				...stagesPerCategory,
				...inactivationsPerAudience,
			}}
		>
			{children}
		</FunnelCpsModuleContext.Provider>
	);
}

export function useFunnelCpsModule() {
	return useContext(FunnelCpsModuleContext);
}
