"use client";

import { useGaModuleAction } from "../hooks/use-ga-module-action";

export const useCpsActionHook = <Data>(
	moduleName: string,
	action: string,
	params: unknown,
	enabled = true,
) => {
	return useGaModuleAction<Data>(moduleName, action, params, enabled);
};
