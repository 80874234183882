import { TableCell, TableRow } from "@mui/material";
import { useCommCpsModule } from "global-analytics";
import { BRL } from "global-analytics";
import { Spinner } from "../../../../../components/Spinner";

export function NetProfitAmountRow() {
	const { netProfitAmount, netProfitRef } = useCommCpsModule();

	return (
		<TableRow ref={netProfitRef}>
			<TableCell>F. Líquido</TableCell>
			{typeof netProfitAmount === "number" ? (
				<TableCell align="right">{BRL.format(netProfitAmount / 100)}</TableCell>
			) : (
				<TableCell align="right">
					<Spinner />
				</TableCell>
			)}
		</TableRow>
	);
}
