"use client";

import { useCallback } from "react";
import { moduleName } from "./constants";
import { useGaModuleAction } from "../hooks/use-ga-module-action";
import useCurrentMonth from "../hooks/use-current-month";

export type InactivationsPerAudienceValue = {
	inactivations_per_audience: Record<string, number | null>;
};

export type InactivationsPerAudienceInstance = {
	audience: string;
	sum: number | null;
};

export const inactivationsPerAudienceDefault: InactivationsPerAudienceValue = {
	inactivations_per_audience: {},
};

export const useInactivationsPerAudience = () => {
	const [startOfMonth, endOfMonth] = useCurrentMonth();
	const { data, isLoading } = useGaModuleAction<
		InactivationsPerAudienceInstance[]
	>(moduleName, "inactivations-per-audience", {
		from: startOfMonth.toISOString(),
		to: endOfMonth.toISOString(),
	});

	const getInactivationsPerAudienceSum = useCallback(
		(audience: string) => {
			if (isLoading || !data) return null;

			const instance = data.find((search) => search.audience === audience);
			return instance?.sum ?? 0;
		},
		[data, isLoading],
	);

	return {
		inactivations_per_audience: {
			"med-br": getInactivationsPerAudienceSum("med-br"),
			"med-ext": getInactivationsPerAudienceSum("med-ext"),
			pd: getInactivationsPerAudienceSum("pd"),
			eoc: getInactivationsPerAudienceSum("eoc"),
			"pd-med-br": getInactivationsPerAudienceSum("pd-med-br"),
			"pd-med-ext": getInactivationsPerAudienceSum("pd-med-ext"),
			others: getInactivationsPerAudienceSum("others"),
		},
	};
};
