import { TableCell, TableRow } from "@mui/material";
import { useCommCpsModule } from "global-analytics";
import { Spinner } from "../../../../../components/Spinner";
import { decimal } from "global-analytics";

export function PdCountRow() {
	const { pdCount, pdRef } = useCommCpsModule();

	return (
		<TableRow ref={pdRef}>
			<TableCell align="left">PD</TableCell>
			{typeof pdCount === "number" ? (
				<TableCell align="right">{decimal.format(pdCount)}</TableCell>
			) : (
				<TableCell align="right">
					<Spinner />
				</TableCell>
			)}
		</TableRow>
	);
}
