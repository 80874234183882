import {
	Grid,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
} from "@mui/material";
import Widget from "../../../../components/Widget";
import { Block } from "../../../../components/Block";
import { ProfitFeesAmountRow } from "./summary/ProfitFeesAmountRow";
import { OrderFeesAmountRow } from "./summary/OrderFeesAmountRow";
import { NetProfitAmountRow } from "./summary/NetProfitAmountRow";
import { CreditCardPercentRow } from "./payment-method/CreditCardPercentRow";
import { PixPercentRow } from "./payment-method/PixPercentRow";
import { BoletoPercentRow } from "./payment-method/BoletoPercentRow";
import { AnnualCountRow } from "./sales-per-product/AnnualCountRow";
import { SemiannualCountRow } from "./sales-per-product/SemiannualCountRow";
import { ComplementCountRow } from "./sales-per-product/ComplementCountRow";
import { MedBrCountRow } from "./sales-per-category/MedBrCountRow";
import { MedExtCountRow } from "./sales-per-category/MedExtCountRow";
import { PdCountRow } from "./sales-per-category/PdCountRow";
import { EocCountRow } from "./sales-per-category/EocCountRow";
import { SemiannualRenewalsCountRow } from "./renewals/SemiannualCountRow";
import { AnnualRenewalsCountRow } from "./renewals/AnnualCountRow";
import { PdMedBrCountRow } from "./sales-per-category/PdMedBrCountRow";
import { PdMedExtCountRow } from "./sales-per-category/PdMedExtCountRow";
import { decimal, useCommCpsModule, useLmsCpsModule } from "global-analytics";
import { Spinner } from "../../../../components/Spinner";
import { OthersCountRow } from "./sales-per-category/OthersCountRow";

export function CommBlock() {
	const {
		annualCount,
		semiannualCount,
		upgradeCount,
		complementCount, //
	} = useCommCpsModule();
	const {
		annualRenewalsCount,
		semiannualRenewalsCount, //
	} = useLmsCpsModule();

	return (
		<Block title="Financeiro" icon="bx:dollar">
			<Grid container spacing={1}>
				<Grid item xs={5}>
					<Widget title="Valores">
						<Table size="small">
							<TableBody>
								<OrderFeesAmountRow />
								<ProfitFeesAmountRow />
								<NetProfitAmountRow />
							</TableBody>
						</Table>
					</Widget>
					<Widget title="Métodos">
						<Table size="small">
							<TableBody>
								<CreditCardPercentRow />
								<PixPercentRow />
								<BoletoPercentRow />
							</TableBody>
						</Table>
					</Widget>
				</Grid>
				<Grid item md={4} xs={12}>
					<Widget title="Estatísticas">
						<Table size="small">
							<TableHead>
								<TableRow>
									<TableCell variant="head">Novas Vendas</TableCell>
									<TableCell variant="body" align="right">
										{typeof annualCount === "number" &&
										typeof annualRenewalsCount === "number" &&
										typeof semiannualCount === "number" &&
										typeof semiannualRenewalsCount === "number" &&
										typeof complementCount === "number" &&
										typeof upgradeCount === "number" ? (
											decimal.format(
												annualCount +
													semiannualCount +
													complementCount -
													annualRenewalsCount -
													semiannualRenewalsCount -
													upgradeCount,
											)
										) : (
											<Spinner />
										)}
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								<AnnualCountRow />
								<SemiannualCountRow />
								<ComplementCountRow />
							</TableBody>
						</Table>
						<Table size="small" sx={{ mt: 1 }}>
							<TableHead>
								<TableRow>
									<TableCell variant="head">Renovações</TableCell>
									<TableCell variant="body" align="right">
										{typeof annualRenewalsCount === "number" &&
										typeof semiannualRenewalsCount === "number" &&
										typeof upgradeCount === "number" ? (
											decimal.format(
												annualRenewalsCount +
													(semiannualRenewalsCount + upgradeCount),
											)
										) : (
											<Spinner />
										)}
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								<AnnualRenewalsCountRow />
								<SemiannualRenewalsCountRow />
							</TableBody>
						</Table>
						<Table size="small" sx={{ mt: 1 }}>
							<TableHead>
								<TableRow>
									<TableCell variant="head">Total geral</TableCell>
									<TableCell variant="body" align="right">
										{typeof annualCount === "number" &&
										typeof semiannualCount === "number" &&
										typeof complementCount === "number" ? (
											/*typeof upgradeCount === "number"*/ decimal.format(
												annualCount + semiannualCount + complementCount,
											)
										) : (
											<Spinner />
										)}
									</TableCell>
								</TableRow>
							</TableHead>
						</Table>
					</Widget>
				</Grid>
				<Grid item xs={3}>
					<Widget title="Categorias">
						<Table size="small">
							<TableBody>
								<MedBrCountRow />
								<MedExtCountRow />
								<PdCountRow />
								<EocCountRow />
								<PdMedBrCountRow />
								<PdMedExtCountRow />
								<OthersCountRow />
							</TableBody>
						</Table>
					</Widget>
				</Grid>
			</Grid>
		</Block>
	);
}
