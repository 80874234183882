import { TableCell, TableRow } from "@mui/material";
import { useCommCpsModule, useLmsCpsModule } from "global-analytics";
import { Spinner } from "../../../../../components/Spinner";
import { decimal } from "global-analytics";

export function SemiannualRenewalsCountRow() {
	const { upgradeCount } = useCommCpsModule();
	const { semiannualRenewalsCount, semiannualRenewalsRef } = useLmsCpsModule();

	const total =
		typeof semiannualRenewalsCount === "number" &&
		typeof upgradeCount === "number"
			? semiannualRenewalsCount + upgradeCount
			: undefined;

	return (
		<TableRow ref={semiannualRenewalsRef}>
			<TableCell align="left">Semestrais</TableCell>
			{typeof total === "number" ? (
				<TableCell align="right">{decimal.format(total)}</TableCell>
			) : (
				<TableCell align="right">
					<Spinner />
				</TableCell>
			)}
		</TableRow>
	);
}
