"use client";

import { RefObject, useRef } from "react";
import { coverageFromRange } from "../cps/coverage-factory";
import { useSumValuesRange } from "../cps/range-hooks";
import { CoveragePosition } from "../cps/types";
import { useGlobalAnalytics } from "../store/context";
import { moduleName } from "./constants";
import { useInView } from "framer-motion";

export type RecordsPerOperatorValue = {
	arthurPreRef: RefObject<HTMLTableRowElement>;
	arthurPreCount: number | null;
	alanaPreRef: RefObject<HTMLTableRowElement>;
	alanaPreCount: number | null;
	renanPreRef: RefObject<HTMLTableRowElement>;
	renanPreCount: number | null;
	amandaPreRef: RefObject<HTMLTableRowElement>;
	amandaPreCount: number | null;

	arthurPostRef: RefObject<HTMLTableRowElement>;
	arthurPostCount: number | null;
	alanaPostRef: RefObject<HTMLTableRowElement>;
	alanaPostCount: number | null;
	renanPostRef: RefObject<HTMLTableRowElement>;
	renanPostCount: number | null;
	amandaPostRef: RefObject<HTMLTableRowElement>;
	amandaPostCount: number | null;
};

export const recordsPerOperatorDefault: RecordsPerOperatorValue = {
	arthurPreRef: { current: null },
	arthurPreCount: null,
	alanaPreRef: { current: null },
	alanaPreCount: null,
	renanPreRef: { current: null },
	renanPreCount: null,
	amandaPreRef: { current: null },
	amandaPreCount: null,

	arthurPostRef: { current: null },
	arthurPostCount: null,
	alanaPostRef: { current: null },
	alanaPostCount: null,
	renanPostRef: { current: null },
	renanPostCount: null,
	amandaPostRef: { current: null },
	amandaPostCount: null,
};

const useRecordsPerOperatorSum = (
	targetRef: RefObject<HTMLTableRowElement>,
	coverageFrom: CoveragePosition,
	coverageTo: CoveragePosition,
	type: string,
	operator: string,
) => {
	const count = useSumValuesRange(
		moduleName,
		"records-per-operator-count",
		coverageFrom,
		coverageTo,
		{
			type: [type],
			operator: [operator],
		},
		useInView(targetRef),
	);

	return count.isLoading || count.error ? null : count.data?.sum ?? 0.0;
};

export const useRecordsPerOperator = () => {
	const { filterState } = useGlobalAnalytics();
	const from = coverageFromRange(filterState.range, "start");
	const to = coverageFromRange(filterState.range, "end");

	const arthurPreRef = useRef<HTMLTableRowElement>(null);
	const alanaPreRef = useRef<HTMLTableRowElement>(null);
	const renanPreRef = useRef<HTMLTableRowElement>(null);
	const amandaPreRef = useRef<HTMLTableRowElement>(null);

	const arthurPostRef = useRef<HTMLTableRowElement>(null);
	const alanaPostRef = useRef<HTMLTableRowElement>(null);
	const renanPostRef = useRef<HTMLTableRowElement>(null);
	const amandaPostRef = useRef<HTMLTableRowElement>(null);

	return {
		arthurPreRef,
		arthurPreCount: useRecordsPerOperatorSum(
			arthurPreRef,
			from,
			to,
			"pre",
			"arthur", //
		),
		alanaPreRef,
		alanaPreCount: useRecordsPerOperatorSum(
			alanaPreRef,
			from,
			to,
			"pre",
			"alana", //
		),
		renanPreRef,
		renanPreCount: useRecordsPerOperatorSum(
			renanPreRef,
			from,
			to,
			"pre",
			"renan", //
		),
		amandaPreRef,
		amandaPreCount: useRecordsPerOperatorSum(
			amandaPreRef,
			from,
			to,
			"pre",
			"amanda", //
		),

		arthurPostRef,
		arthurPostCount: useRecordsPerOperatorSum(
			arthurPostRef,
			from,
			to,
			"post",
			"arthur", //
		),
		alanaPostRef,
		alanaPostCount: useRecordsPerOperatorSum(
			alanaPostRef,
			from,
			to,
			"post",
			"alana", //
		),
		renanPostRef,
		renanPostCount: useRecordsPerOperatorSum(
			renanPostRef,
			from,
			to,
			"post",
			"renan", //
		),
		amandaPostRef,
		amandaPostCount: useRecordsPerOperatorSum(
			amandaPostRef,
			from,
			to,
			"post",
			"amanda", //
		),
	};
};
