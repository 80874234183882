"use client";

import { RefObject, useRef } from "react";
import { coverageFromRange } from "../cps/coverage-factory";
import { useSumValuesRange } from "../cps/range-hooks";
import { CoveragePosition } from "../cps/types";
import { useGlobalAnalytics } from "../store/context";
import { moduleName } from "./constants";
import { useInView } from "framer-motion";

export type SpendPerTagValue = {
	facebookConversionsRef: RefObject<HTMLTableRowElement>;
	facebookConversionsAmount: number | null;
	facebookEngagementRef: RefObject<HTMLTableRowElement>;
	facebookEngagementAmount: number | null;
	facebookRemarketingSalesRef: RefObject<HTMLTableRowElement>;
	facebookRemarketingSalesAmount: number | null;

	googleConversionsRef: RefObject<HTMLTableRowElement>;
	googleConversionsAmount: number | null;
	googleEngagementRef: RefObject<HTMLTableRowElement>;
	googleEngagementAmount: number | null;
	googleRemarketingSalesRef: RefObject<HTMLTableRowElement>;
	googleRemarketingSalesAmount: number | null;
};

export const spendPerTagDefault: SpendPerTagValue = {
	facebookConversionsRef: { current: null },
	facebookConversionsAmount: null,
	facebookEngagementRef: { current: null },
	facebookEngagementAmount: null,
	facebookRemarketingSalesRef: { current: null },
	facebookRemarketingSalesAmount: null,

	googleConversionsRef: { current: null },
	googleConversionsAmount: null,
	googleEngagementRef: { current: null },
	googleEngagementAmount: null,
	googleRemarketingSalesRef: { current: null },
	googleRemarketingSalesAmount: null,
};

const useSpendPerTagSum = (
	targetRef: RefObject<HTMLTableRowElement>,
	coverageFrom: CoveragePosition,
	coverageTo: CoveragePosition,
	tag: string,
	source: string,
) => {
	const amount = useSumValuesRange(
		moduleName,
		"spend-per-tag-amount",
		coverageFrom,
		coverageTo,
		{ tags: [tag], source: [source] },
		useInView(targetRef),
	);

	return amount.isLoading || amount.error ? null : amount.data?.sum ?? 0.0;
};

export const useSpendPerTag = () => {
	const { filterState } = useGlobalAnalytics();
	const from = coverageFromRange(filterState.range, "start");
	const to = coverageFromRange(filterState.range, "end");

	const facebookConversionsRef = useRef<HTMLTableRowElement>(null);
	const facebookEngagementRef = useRef<HTMLTableRowElement>(null);
	const facebookRemarketingSalesRef = useRef<HTMLTableRowElement>(null);

	const googleConversionsRef = useRef<HTMLTableRowElement>(null);
	const googleEngagementRef = useRef<HTMLTableRowElement>(null);
	const googleRemarketingSalesRef = useRef<HTMLTableRowElement>(null);

	return {
		// Facebook
		facebookConversionsRef: facebookConversionsRef,
		facebookConversionsAmount: useSpendPerTagSum(
			facebookConversionsRef,
			from,
			to,
			"conversions",
			"facebook",
		),

		facebookEngagementRef: facebookEngagementRef,
		facebookEngagementAmount: useSpendPerTagSum(
			facebookEngagementRef,
			from,
			to,
			"engagement",
			"facebook",
		),

		facebookRemarketingSalesRef: facebookRemarketingSalesRef,
		facebookRemarketingSalesAmount: useSpendPerTagSum(
			facebookRemarketingSalesRef,
			from,
			to,
			"remarketing_sales",
			"facebook",
		),

		// Google
		googleConversionsRef: googleConversionsRef,
		googleConversionsAmount: useSpendPerTagSum(
			googleConversionsRef,
			from,
			to,
			"conversions",
			"google",
		),

		googleEngagementRef: googleEngagementRef,
		googleEngagementAmount: useSpendPerTagSum(
			googleEngagementRef,
			from,
			to,
			"engagement",
			"google",
		),

		googleRemarketingSalesRef: googleRemarketingSalesRef,
		googleRemarketingSalesAmount: useSpendPerTagSum(
			googleRemarketingSalesRef,
			from,
			to,
			"remarketing_sales",
			"google",
		),
	};
};
