"use client";

import { ReactNode, createContext, useContext } from "react";
import {
	visitsCountDefault,
	VisitsCountValue,
	useVisitsCount,
} from "./visits-count";

type PagesCpsModuleContextValue = VisitsCountValue;

const PagesCpsModuleContext = createContext<PagesCpsModuleContextValue>({
	...visitsCountDefault,
});

export function PagesCpsModuleProvider({ children }: { children: ReactNode }) {
	const visitsCount = useVisitsCount();

	return (
		<PagesCpsModuleContext.Provider
			value={{
				...visitsCount,
			}}
		>
			{children}
		</PagesCpsModuleContext.Provider>
	);
}

export function usePagesCpsModule() {
	return useContext(PagesCpsModuleContext);
}
