"use client";

import { RefObject, useRef } from "react";
import { coverageFromRange } from "../cps/coverage-factory";
import { useSumValuesRange } from "../cps/range-hooks";
import { CoveragePosition } from "../cps/types";
import { useGlobalAnalytics } from "../store/context";
import { moduleName } from "./constants";
import { useInView } from "framer-motion";

export type RecordsPerCategoryValue = {
	medBrPreRef: RefObject<HTMLTableRowElement>;
	medBrPreCount: number | null;
	medExtPreRef: RefObject<HTMLTableRowElement>;
	medExtPreCount: number | null;
	pdPreRef: RefObject<HTMLTableRowElement>;
	pdPreCount: number | null;
	pdMedBrPreRef: RefObject<HTMLTableRowElement>;
	pdMedBrPreCount: number | null;
	pdMedExtPreRef: RefObject<HTMLTableRowElement>;
	pdMedExtPreCount: number | null;
	eocPreRef: RefObject<HTMLTableRowElement>;
	eocPreCount: number | null;
	othersPreRef: RefObject<HTMLTableRowElement>;
	othersPreCount: number | null;

	medBrPostRef: RefObject<HTMLTableRowElement>;
	medBrPostCount: number | null;
	medExtPostRef: RefObject<HTMLTableRowElement>;
	medExtPostCount: number | null;
	pdPostRef: RefObject<HTMLTableRowElement>;
	pdPostCount: number | null;
	pdMedBrPostRef: RefObject<HTMLTableRowElement>;
	pdMedBrPostCount: number | null;
	pdMedExtPostRef: RefObject<HTMLTableRowElement>;
	pdMedExtPostCount: number | null;
	eocPostRef: RefObject<HTMLTableRowElement>;
	eocPostCount: number | null;
	othersPostRef: RefObject<HTMLTableRowElement>;
	othersPostCount: number | null;
};

export const recordsPerCategoryDefault: RecordsPerCategoryValue = {
	medBrPreRef: { current: null },
	medBrPreCount: null,
	medExtPreRef: { current: null },
	medExtPreCount: null,
	pdPreRef: { current: null },
	pdPreCount: null,
	pdMedBrPreRef: { current: null },
	pdMedBrPreCount: null,
	pdMedExtPreRef: { current: null },
	pdMedExtPreCount: null,
	eocPreRef: { current: null },
	eocPreCount: null,
	othersPreRef: { current: null },
	othersPreCount: null,

	medBrPostRef: { current: null },
	medBrPostCount: null,
	medExtPostRef: { current: null },
	medExtPostCount: null,
	pdPostRef: { current: null },
	pdPostCount: null,
	pdMedBrPostRef: { current: null },
	pdMedBrPostCount: null,
	pdMedExtPostRef: { current: null },
	pdMedExtPostCount: null,
	eocPostRef: { current: null },
	eocPostCount: null,
	othersPostRef: { current: null },
	othersPostCount: null,
};

const useRecordsPerCategorySum = (
	targetRef: RefObject<HTMLTableRowElement>,
	coverageFrom: CoveragePosition,
	coverageTo: CoveragePosition,
	type: string,
	category: string,
) => {
	const count = useSumValuesRange(
		moduleName,
		"records-per-category-count",
		coverageFrom,
		coverageTo,
		{
			type: [type],
			category: [category],
		},
		useInView(targetRef),
	);

	return count.isLoading || count.error ? null : count.data?.sum ?? 0.0;
};

export const useRecordsPerCategory = () => {
	const { filterState } = useGlobalAnalytics();
	const from = coverageFromRange(filterState.range, "start");
	const to = coverageFromRange(filterState.range, "end");

	const medBrPreRef = useRef<HTMLTableRowElement>(null);
	const medExtPreRef = useRef<HTMLTableRowElement>(null);
	const pdPreRef = useRef<HTMLTableRowElement>(null);
	const pdMedBrPreRef = useRef<HTMLTableRowElement>(null);
	const pdMedExtPreRef = useRef<HTMLTableRowElement>(null);
	const eocPreRef = useRef<HTMLTableRowElement>(null);
	const othersPreRef = useRef<HTMLTableRowElement>(null);

	const medBrPostRef = useRef<HTMLTableRowElement>(null);
	const medExtPostRef = useRef<HTMLTableRowElement>(null);
	const pdPostRef = useRef<HTMLTableRowElement>(null);
	const pdMedBrPostRef = useRef<HTMLTableRowElement>(null);
	const pdMedExtPostRef = useRef<HTMLTableRowElement>(null);
	const eocPostRef = useRef<HTMLTableRowElement>(null);
	const othersPostRef = useRef<HTMLTableRowElement>(null);

	return {
		medBrPreRef,
		medBrPreCount: useRecordsPerCategorySum(
			medBrPreRef,
			from,
			to,
			"pre",
			"med-br",
		),
		medExtPreRef,
		medExtPreCount: useRecordsPerCategorySum(
			medExtPreRef,
			from,
			to,
			"pre",
			"med-ext",
		),
		pdPreRef,
		pdPreCount: useRecordsPerCategorySum(
			pdPreRef,
			from,
			to,
			"pre",
			"pd", //
		),
		pdMedBrPreRef,
		pdMedBrPreCount: useRecordsPerCategorySum(
			pdMedBrPreRef,
			from,
			to,
			"pre",
			"pd-med-br",
		),
		pdMedExtPreRef,
		pdMedExtPreCount: useRecordsPerCategorySum(
			pdMedExtPreRef,
			from,
			to,
			"pre",
			"pd-med-ext",
		),
		eocPreRef,
		eocPreCount: useRecordsPerCategorySum(
			eocPreRef,
			from,
			to,
			"pre",
			"eoc", //
		),
		othersPreRef,
		othersPreCount: useRecordsPerCategorySum(
			othersPreRef,
			from,
			to,
			"pre",
			"others", //
		),

		medBrPostRef,
		medBrPostCount: useRecordsPerCategorySum(
			medBrPostRef,
			from,
			to,
			"post",
			"med-br",
		),
		medExtPostRef,
		medExtPostCount: useRecordsPerCategorySum(
			medExtPostRef,
			from,
			to,
			"post",
			"med-ext",
		),
		pdPostRef,
		pdPostCount: useRecordsPerCategorySum(
			pdPostRef,
			from,
			to,
			"post",
			"pd", //
		),
		pdMedBrPostRef,
		pdMedBrPostCount: useRecordsPerCategorySum(
			pdMedBrPostRef,
			from,
			to,
			"post",
			"pd-med-br",
		),
		pdMedExtPostRef,
		pdMedExtPostCount: useRecordsPerCategorySum(
			pdMedExtPostRef,
			from,
			to,
			"post",
			"pd-med-ext",
		),
		eocPostRef,
		eocPostCount: useRecordsPerCategorySum(
			eocPostRef,
			from,
			to,
			"post",
			"eoc", //
		),
		othersPostRef,
		othersPostCount: useRecordsPerCategorySum(
			othersPostRef,
			from,
			to,
			"post",
			"others", //
		),
	};
};
