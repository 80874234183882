"use client";

import { ReactNode, createContext, useContext } from "react";
import {
	accountsEngagedCountDefault,
	AccountsEngagedCountValue,
	useAccountsEngagedCount,
} from "./accounts-engaged-count";
import {
	BioClicksCountValue,
	bioClicksCountDefault,
	useBioClicksCount,
} from "./bio-clicks-count";
import {
	ProfileViewsCountValue,
	useProfileViewsCount,
	profileViewsCountDefault,
} from "./profile-views-count";
import {
	FollowersCountValue,
	followersCountDefault,
	useFollowersCount,
} from "./followers-count";
import {
	GrowthCountValue,
	growthCountDefault,
	useGrowthCount,
} from "./growth-count";

type SocialCpsModuleContextValue = AccountsEngagedCountValue &
	BioClicksCountValue &
	FollowersCountValue &
	GrowthCountValue &
	ProfileViewsCountValue;

const SocialCpsModuleContext = createContext<SocialCpsModuleContextValue>({
	...accountsEngagedCountDefault,
	...bioClicksCountDefault,
	...followersCountDefault,
	...growthCountDefault,
	...profileViewsCountDefault,
});

export function SocialCpsModuleProvider({ children }: { children: ReactNode }) {
	const accountsEngagedCount = useAccountsEngagedCount();
	const bioClicksCount = useBioClicksCount();
	const followersCount = useFollowersCount();
	const growthCount = useGrowthCount();
	const profileViewsCount = useProfileViewsCount();

	return (
		<SocialCpsModuleContext.Provider
			value={{
				...accountsEngagedCount,
				...bioClicksCount,
				...followersCount,
				...growthCount,
				...profileViewsCount,
			}}
		>
			{children}
		</SocialCpsModuleContext.Provider>
	);
}

export function useSocialCpsModule() {
	return useContext(SocialCpsModuleContext);
}
