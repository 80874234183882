"use client";

import { FetcherInterface } from "../contracts/fetcher.interface";
import { useGlobalAnalytics } from "../store/context";
import useSWR from "swr";

export const useGaModuleAction = <Data>(
	moduleName: string,
	action: string,
	params: unknown,
	enabled = true,
) => {
	const { fetcher } = useGlobalAnalytics();
	const route = `ga/modules/${moduleName}/${action}`;
	const body = JSON.stringify(params);

	return useSWR<Data>(enabled ? [route, body] : false, {
		fetcher: fetcher as FetcherInterface<Data>,
		revalidateOnMount: true,
		revalidateOnReconnect: true,
		revalidateIfStale: true,
		revalidateOnFocus: false,
		keepPreviousData: false,
		refreshInterval: 60000,
	});
};
