import { TableCell, TableRow } from "@mui/material";
import { useCommCpsModule } from "global-analytics";
import { Spinner } from "../../../../../components/Spinner";
import { decimal } from "global-analytics";

export function OthersCountRow() {
	const { othersCount, othersRef } = useCommCpsModule();

	return (
		<TableRow ref={othersRef}>
			<TableCell align="left">Outros</TableCell>
			{typeof othersCount === "number" ? (
				<TableCell align="right">{decimal.format(othersCount)}</TableCell>
			) : (
				<TableCell align="right">
					<Spinner />
				</TableCell>
			)}
		</TableRow>
	);
}
