import { TableCell, TableRow } from "@mui/material";
import { useCommCpsModule } from "global-analytics";
import { Spinner } from "../../../../../components/Spinner";
import { decimal } from "global-analytics";

export function EocCountRow() {
	const { eocCount, eocRef } = useCommCpsModule();

	return (
		<TableRow ref={eocRef}>
			<TableCell align="left">EOC</TableCell>
			{typeof eocCount === "number" ? (
				<TableCell align="right">{decimal.format(eocCount)}</TableCell>
			) : (
				<TableCell align="right">
					<Spinner />
				</TableCell>
			)}
		</TableRow>
	);
}
