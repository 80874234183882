"use client";

import { FilterState, GlobalAnalytics } from "global-analytics";
import { fetcher } from "./fetcher";
import { ReactNode, useEffect, useState } from "react";
import { LoaderUI } from "./LoaderUI";
import { useSocket } from "../socket";

export default function GlobalAnalyticsProvider({
	children,
}: {
	children: ReactNode;
}) {
	const [filters, dispatchFilters] = useState<FilterState | null>(null);
	const { send, on, isConnected } = useSocket();

	useEffect(() => {
		if (!isConnected) return;

		// Trigger every reconnection.
		send<FilterState>("v1.filter-state").then(dispatchFilters);
	}, [send, isConnected]);

	useEffect(
		() =>
			on<FilterState>("v1.filter-state", (filters) => {
				dispatchFilters((prevState) => {
					if (!prevState) return prevState;

					if (prevState.iat > filters.iat) {
						return prevState;
					}

					return filters;
				});
			}),
		[on],
	);

	return filters ? (
		<GlobalAnalytics
			options={{
				fetcher,
			}}
			filters={filters}
		>
			{children}
		</GlobalAnalytics>
	) : (
		<LoaderUI />
	);
}
