"use client";

import { useCallback } from "react";
import { moduleName } from "./constants";
import { useGaModuleAction } from "../hooks/use-ga-module-action";

export type StagesPerCategoryValue = {
	top: Record<string, number | null>;
	middle: Record<string, number | null>;
	bottom: Record<string, number | null>;
	student_reserved: Record<string, number | null>;
	customer_active: Record<string, number | null>;
	customer_inactive: Record<string, number | null>;
	others: Record<string, number | null>;
};

export type StagesPerCategoryInstance = {
	category: string;
	stage: string;
	sum: number | null;
};

export const stagesPerCategoryDefault: StagesPerCategoryValue = {
	top: {},
	middle: {},
	bottom: {},
	student_reserved: {},
	customer_active: {},
	customer_inactive: {},
	others: {},
};

export const useStagesPerCategory = () => {
	const { data, isLoading } = useGaModuleAction<StagesPerCategoryInstance[]>(
		moduleName,
		"stages-per-category",
		{},
	);

	const getStagesPerCategorySum = useCallback(
		(category: string, stage: string) => {
			if (isLoading || !data) return null;

			const instance = data.find(
				(search) => search.category === category && search.stage === stage,
			);
			return instance?.sum ?? 0;
		},
		[data, isLoading],
	);

	return {
		top: {
			"med-br": getStagesPerCategorySum("med-br", "top"),
			"med-ext": getStagesPerCategorySum("med-ext", "top"),
			pd: getStagesPerCategorySum("pd", "top"),
			eoc: getStagesPerCategorySum("eoc", "top"),
			"pd-med-br": getStagesPerCategorySum("pd-med-br", "top"),
			"pd-med-ext": getStagesPerCategorySum("pd-med-ext", "top"),
			others: getStagesPerCategorySum("others", "top"),
		},
		middle: {
			"med-br": getStagesPerCategorySum("med-br", "middle"),
			"med-ext": getStagesPerCategorySum("med-ext", "middle"),
			pd: getStagesPerCategorySum("pd", "middle"),
			eoc: getStagesPerCategorySum("eoc", "middle"),
			"pd-med-br": getStagesPerCategorySum("pd-med-br", "middle"),
			"pd-med-ext": getStagesPerCategorySum("pd-med-ext", "middle"),
			others: getStagesPerCategorySum("others", "middle"),
		},
		bottom: {
			"med-br": getStagesPerCategorySum("med-br", "bottom"),
			"med-ext": getStagesPerCategorySum("med-ext", "bottom"),
			pd: getStagesPerCategorySum("pd", "bottom"),
			eoc: getStagesPerCategorySum("eoc", "bottom"),
			"pd-med-br": getStagesPerCategorySum("pd-med-br", "bottom"),
			"pd-med-ext": getStagesPerCategorySum("pd-med-ext", "bottom"),
			others: getStagesPerCategorySum("others", "bottom"),
		},
		student_reserved: {
			"med-br": getStagesPerCategorySum("med-br", "student_reserved"),
			"med-ext": getStagesPerCategorySum("med-ext", "student_reserved"),
			pd: getStagesPerCategorySum("pd", "student_reserved"),
			eoc: getStagesPerCategorySum("eoc", "student_reserved"),
			"pd-med-br": getStagesPerCategorySum("pd-med-br", "student_reserved"),
			"pd-med-ext": getStagesPerCategorySum("pd-med-ext", "student_reserved"),
			others: getStagesPerCategorySum("others", "student_reserved"),
		},
		customer_active: {
			"med-br": getStagesPerCategorySum("med-br", "customer_active"),
			"med-ext": getStagesPerCategorySum("med-ext", "customer_active"),
			pd: getStagesPerCategorySum("pd", "customer_active"),
			eoc: getStagesPerCategorySum("eoc", "customer_active"),
			"pd-med-br": getStagesPerCategorySum("pd-med-br", "customer_active"),
			"pd-med-ext": getStagesPerCategorySum("pd-med-ext", "customer_active"),
			others: getStagesPerCategorySum("others", "customer_active"),
		},
		customer_inactive: {
			"med-br": getStagesPerCategorySum("med-br", "customer_inactive"),
			"med-ext": getStagesPerCategorySum("med-ext", "customer_inactive"),
			pd: getStagesPerCategorySum("pd", "customer_inactive"),
			eoc: getStagesPerCategorySum("eoc", "customer_inactive"),
			"pd-med-br": getStagesPerCategorySum("pd-med-br", "customer_inactive"),
			"pd-med-ext": getStagesPerCategorySum("pd-med-ext", "customer_inactive"),
			others: getStagesPerCategorySum("others", "customer_inactive"),
		},
		others: {
			"med-br": getStagesPerCategorySum("med-br", "others"),
			"med-ext": getStagesPerCategorySum("med-ext", "others"),
			pd: getStagesPerCategorySum("pd", "others"),
			eoc: getStagesPerCategorySum("eoc", "others"),
			"pd-med-br": getStagesPerCategorySum("pd-med-br", "others"),
			"pd-med-ext": getStagesPerCategorySum("pd-med-ext", "others"),
			others: getStagesPerCategorySum("others", "others"),
		},
	};
};
