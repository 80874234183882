"use client";

import moment from "moment-timezone";
import { coverageFromRange } from "../cps/coverage-factory";
import { useSumValuesRange } from "../cps/range-hooks";
import { CoveragePosition } from "../cps/types";
import { useGlobalAnalytics } from "../store/context";
import { moduleName } from "./constants";

export type FollowersCountValue = {
	followersCount: number | null;
	followersPosition: CoveragePosition | null;
};

export const followersCountDefault: FollowersCountValue = {
	followersCount: null,
	followersPosition: null,
};

const useFollowersSum = (position: CoveragePosition) => {
	const count = useSumValuesRange(
		moduleName,
		"followers-count",
		position,
		position,
	);

	return count.isLoading || count.error ? null : count.data?.sum ?? 0.0;
};

export const useFollowersCount = () => {
	const { filterState } = useGlobalAnalytics();
	// Only use the end date for the followers count.

	const now = new Date();
	const isFuture = moment(filterState.range[1]).isAfter(now);
	const rangeInput: [string, string] = isFuture
		? [filterState.range[0], now.toISOString()]
		: filterState.range;

	const position = coverageFromRange(rangeInput, "end");

	return {
		followersCount: useFollowersSum(position),
		followersPosition: position,
	};
};
