import { TableCell, TableRow } from "@mui/material";
import { useCommCpsModule } from "global-analytics";
import { BRL } from "global-analytics";
import { Spinner } from "../../../../../components/Spinner";

export function OrderFeesAmountRow() {
	const { orderFeesAmount, orderFeesRef } = useCommCpsModule();

	return (
		<TableRow ref={orderFeesRef}>
			<TableCell>Cartão de Crédito</TableCell>
			{typeof orderFeesAmount === "number" ? (
				<TableCell align="right">{BRL.format(orderFeesAmount / 100)}</TableCell>
			) : (
				<TableCell align="right">
					<Spinner />
				</TableCell>
			)}
		</TableRow>
	);
}
