import moment, { Moment } from "moment-timezone";
import { useEffect, useMemo, useState } from "react";

export default function useCurrentMonth(): [Moment, Moment] {
	const [, dispatchRender] = useState(0);

	useEffect(() => {
		setTimeout(() => dispatchRender((v) => v + 1), 60000);
	}, []);

	const startOfDayDep = moment()
		.tz("America/Sao_Paulo")
		.startOf("day")
		.toISOString();

	return useMemo(() => {
		const startOfDay = moment(startOfDayDep).tz("America/Sao_Paulo");

		return [
			startOfDay.clone().startOf("month"),
			startOfDay.clone().endOf("month"),
		];
	}, [startOfDayDep]);
}
