import { TableCell, TableRow } from "@mui/material";
import { useCommCpsModule } from "global-analytics";
import { Spinner } from "../../../../../components/Spinner";
import { decimal } from "global-analytics";

export function MedBrCountRow() {
	const { medBrCount, medBrRef } = useCommCpsModule();

	return (
		<TableRow ref={medBrRef}>
			<TableCell align="left">BR</TableCell>
			{typeof medBrCount === "number" ? (
				<TableCell align="right">{decimal.format(medBrCount)}</TableCell>
			) : (
				<TableCell align="right">
					<Spinner />
				</TableCell>
			)}
		</TableRow>
	);
}
