import { TableCell, TableRow } from "@mui/material";
import { useCommCpsModule } from "global-analytics";
import { Spinner } from "../../../../../components/Spinner";
import { decimal } from "global-analytics";

export function PdMedExtCountRow() {
	const { pdMedExtCount, pdMedExtRef } = useCommCpsModule();

	return (
		<TableRow ref={pdMedExtRef}>
			<TableCell align="left">PD + EXT</TableCell>
			{typeof pdMedExtCount === "number" ? (
				<TableCell align="right">{decimal.format(pdMedExtCount)}</TableCell>
			) : (
				<TableCell align="right">
					<Spinner />
				</TableCell>
			)}
		</TableRow>
	);
}
