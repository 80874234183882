"use client";

import { coverageFromRange } from "../cps/coverage-factory";
import { useSumValuesRange } from "../cps/range-hooks";
import { CoveragePosition } from "../cps/types";
import { useGlobalAnalytics } from "../store/context";
import { moduleName } from "./constants";

export type ConversionsValue = {
	leadHolderCount: number | null;
	visitorHolderCount: number | null;
	//
	paidMedBrConversionsCount: number | null;
	paidMedExtConversionsCount: number | null;
	paidPdConversionsCount: number | null;
	paidPdMedBrConversionsCount: number | null;
	paidPdMedExtConversionsCount: number | null;
	paidEocConversionsCount: number | null;
	paidOthersConversionsCount: number | null;
	//
	organicMedBrConversionsCount: number | null;
	organicMedExtConversionsCount: number | null;
	organicPdConversionsCount: number | null;
	organicPdMedBrConversionsCount: number | null;
	organicPdMedExtConversionsCount: number | null;
	organicEocConversionsCount: number | null;
	organicOthersConversionsCount: number | null;
	//
	googlePaidMediumConversionsCount: number | null;
	facebookPaidMediumConversionsCount: number | null;
	othersPaidMediumConversionCount: number | null;
	organicMediumConversionsCount: number | null;
};

export const conversionsDefault: ConversionsValue = {
	leadHolderCount: null,
	visitorHolderCount: null,
	//
	paidMedBrConversionsCount: null,
	paidMedExtConversionsCount: null,
	paidPdConversionsCount: null,
	paidPdMedBrConversionsCount: null,
	paidPdMedExtConversionsCount: null,
	paidEocConversionsCount: null,
	paidOthersConversionsCount: null,
	//
	organicMedBrConversionsCount: null,
	organicMedExtConversionsCount: null,
	organicPdConversionsCount: null,
	organicPdMedBrConversionsCount: null,
	organicPdMedExtConversionsCount: null,
	organicEocConversionsCount: null,
	organicOthersConversionsCount: null,
	//
	googlePaidMediumConversionsCount: null,
	facebookPaidMediumConversionsCount: null,
	othersPaidMediumConversionCount: null,
	organicMediumConversionsCount: null,
};

const useConversionsSum = (
	coverageFrom: CoveragePosition,
	coverageTo: CoveragePosition,
	holder: string | null,
	category: string | null,
) => {
	// Do not use inView! Others metrics composes these metrics.

	const count = useSumValuesRange(
		moduleName,
		"conversions-count",
		coverageFrom,
		coverageTo,
		{
			...(holder
				? {
						holder: [holder],
				  }
				: {}),
			...(category
				? {
						category: [category],
				  }
				: {}),
		},
	);

	return count.isLoading || count.error ? null : count.data?.sum ?? 0.0;
};

const useConversionsTrafficSum = (
	coverageFrom: CoveragePosition,
	coverageTo: CoveragePosition,
	source: string | null,
	medium: string | null,
	category: string | null,
) => {
	// Do not use inView! Others metrics composes these metrics.

	const count = useSumValuesRange(
		moduleName,
		"conversions-traffic-count",
		coverageFrom,
		coverageTo,
		{
			...(source
				? {
						source: [source],
				  }
				: {}),
			...(medium
				? {
						medium: [medium],
				  }
				: {}),
			...(category
				? {
						category: [category],
				  }
				: {}),
		},
	);

	return count.isLoading || count.error ? null : count.data?.sum ?? 0.0;
};

export const useConversions = () => {
	const { filterState } = useGlobalAnalytics();
	const from = coverageFromRange(filterState.range, "start");
	const to = coverageFromRange(filterState.range, "end");

	return {
		leadHolderCount: useConversionsSum(from, to, "lead", null),
		visitorHolderCount: useConversionsSum(from, to, "visitor", null),
		//
		paidMedBrConversionsCount: useConversionsTrafficSum(
			from,
			to,
			"paid",
			null,
			"med-br",
		),
		paidMedExtConversionsCount: useConversionsTrafficSum(
			from,
			to,
			"paid",
			null,
			"med-ext",
		),
		paidPdConversionsCount: useConversionsTrafficSum(
			from,
			to,
			"paid",
			null,
			"pd",
		),
		paidPdMedBrConversionsCount: useConversionsTrafficSum(
			from,
			to,
			"paid",
			null,
			"pd-med-br",
		),
		paidPdMedExtConversionsCount: useConversionsTrafficSum(
			from,
			to,
			"paid",
			null,
			"pd-med-ext",
		),
		paidEocConversionsCount: useConversionsTrafficSum(
			from,
			to,
			"paid",
			null,
			"eoc",
		),
		paidOthersConversionsCount: useConversionsTrafficSum(
			from,
			to,
			"paid",
			null,
			"others",
		),
		//
		organicMedBrConversionsCount: useConversionsTrafficSum(
			from,
			to,
			"organic",
			null,
			"med-br",
		),
		organicMedExtConversionsCount: useConversionsTrafficSum(
			from,
			to,
			"organic",
			null,
			"med-ext",
		),
		organicPdConversionsCount: useConversionsTrafficSum(
			from,
			to,
			"organic",
			null,
			"pd",
		),
		organicPdMedBrConversionsCount: useConversionsTrafficSum(
			from,
			to,
			"organic",
			null,
			"pd-med-br",
		),
		organicPdMedExtConversionsCount: useConversionsTrafficSum(
			from,
			to,
			"organic",
			null,
			"pd-med-ext",
		),
		organicEocConversionsCount: useConversionsTrafficSum(
			from,
			to,
			"organic",
			null,
			"eoc",
		),
		organicOthersConversionsCount: useConversionsTrafficSum(
			from,
			to,
			"organic",
			null,
			"others",
		),
		//
		googlePaidMediumConversionsCount: useConversionsTrafficSum(
			from,
			to,
			"paid",
			"google",
			null,
		),
		facebookPaidMediumConversionsCount: useConversionsTrafficSum(
			from,
			to,
			"paid",
			"facebook",
			null,
		),
		othersPaidMediumConversionCount: useConversionsTrafficSum(
			from,
			to,
			"paid",
			"others",
			null,
		),
		organicMediumConversionsCount: useConversionsTrafficSum(
			from,
			to,
			"organic",
			null,
			null,
		),
	};
};
