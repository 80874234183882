import { Box, Typography, styled } from "@mui/material";
import { ReactNode } from "react";

const Root = styled(Box, {
	target: "GA_Widget",
	shouldForwardProp: (prop) => prop !== "disabled",
})<{
	disabled?: boolean;
}>(({ theme, disabled }) => ({
	overflow: "auto",

	"& + .GA_Widget": {
		marginTop: theme.spacing(1),
	},

	opacity: disabled ? 0.4 : 1,
}));

export default function Widget({
	title,
	disabled,
	children,
}: {
	title?: string;
	disabled?: boolean;
	children: ReactNode;
}) {
	return (
		<Root disabled={disabled}>
			{title ? (
				<Typography
					variant="overline"
					display="block"
					fontWeight={600}
					textAlign="left"
					color="text.secondary"
					sx={{
						pl: 1,
						pt: 0,
					}}
				>
					{title}
				</Typography>
			) : null}
			{children}
		</Root>
	);
}
