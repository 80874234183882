import { ReactNode } from "react";
import { GlobalAnalyticsOptions } from "./contracts/global-analytics.interface";
import { GlobalAnalyticsProvider } from "./store/context";
import { FilterState } from "./contracts/filters";

export interface GlobalAnalyticsProps {
	options: GlobalAnalyticsOptions;
	filters: FilterState;
	children: ReactNode;
}

export function GlobalAnalytics({
	options,
	filters,
	children,
}: GlobalAnalyticsProps) {
	return (
		<GlobalAnalyticsProvider fetcher={options.fetcher} filterState={filters}>
			{children}
		</GlobalAnalyticsProvider>
	);
}

export default GlobalAnalytics;
