import { TableCell, TableRow } from "@mui/material";
import { useCommCpsModule } from "global-analytics";
import { Spinner } from "../../../../../components/Spinner";
import { decimal } from "global-analytics";

export function PdMedBrCountRow() {
	const { pdMedBrCount, pdMedBrRef } = useCommCpsModule();

	return (
		<TableRow ref={pdMedBrRef}>
			<TableCell align="left">PD + BR</TableCell>
			{typeof pdMedBrCount === "number" ? (
				<TableCell align="right">{decimal.format(pdMedBrCount)}</TableCell>
			) : (
				<TableCell align="right">
					<Spinner />
				</TableCell>
			)}
		</TableRow>
	);
}
