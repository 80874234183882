import {
	Chip,
	Grid,
	Table,
	TableBody,
	TableCell,
	TableRow,
} from "@mui/material";
import Widget from "../../../../components/Widget";
import { Block } from "../../../../components/Block";
import { decimal, useSocialCpsModule } from "global-analytics";
import { Spinner } from "../../../../components/Spinner";
import moment from "moment-timezone";

export function SocialBlock() {
	const {
		followersCount,
		followersPosition,
		accountsEngagedCount,
		bioClicksCount,
		balanceCount,
		increaseCount,
		decreaseCount,
		profileViewsCount,
	} = useSocialCpsModule();

	return (
		<Block title="Redes Sociais" icon="mdi:instagram">
			<Grid container spacing={1}>
				<Grid item xs={6}>
					<Widget>
						<Table size="small">
							<TableBody>
								<TableRow>
									<TableCell>
										Seguidores{" "}
										<Chip
											label={
												(typeof followersCount === "number" &&
													// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
													moment(followersPosition!.day, "YYYY-MM-DD").format(
														"DD/MM/YYYY",
													)) || <Spinner />
											}
											color="secondary"
											size="small"
											sx={{ ml: 0.8 }}
										/>
									</TableCell>
									<TableCell align="right">
										{typeof followersCount === "number" ? (
											decimal.format(followersCount)
										) : (
											<Spinner />
										)}
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell>Crescimento</TableCell>
									<TableCell align="right">
										{typeof balanceCount === "number" ? (
											decimal.format(balanceCount)
										) : (
											<Spinner />
										)}
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell>Novos Seguidores</TableCell>
									<TableCell align="right">
										{typeof increaseCount === "number" ? (
											decimal.format(increaseCount)
										) : (
											<Spinner />
										)}
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell>Deixaram de Seguir</TableCell>
									<TableCell align="right">
										{typeof decreaseCount === "number" ? (
											decimal.format(decreaseCount)
										) : (
											<Spinner />
										)}
									</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					</Widget>
				</Grid>
				<Grid item xs={6}>
					<Widget title="Métricas">
						<Table size="small">
							<TableBody>
								<TableRow>
									<TableCell>Visitas ao Perfil</TableCell>
									<TableCell align="right">
										{typeof profileViewsCount === "number" ? (
											decimal.format(profileViewsCount)
										) : (
											<Spinner />
										)}
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell>Contas Engajadas</TableCell>
									<TableCell align="right">
										{typeof accountsEngagedCount === "number" ? (
											decimal.format(accountsEngagedCount)
										) : (
											<Spinner />
										)}
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell>Cliques na Bio</TableCell>
									<TableCell align="right">
										{typeof bioClicksCount === "number" ? (
											decimal.format(bioClicksCount)
										) : (
											<Spinner />
										)}
									</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					</Widget>
				</Grid>
			</Grid>
		</Block>
	);
}
