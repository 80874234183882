import { TableCell, TableRow } from "@mui/material";
import { useCommCpsModule, useLmsCpsModule } from "global-analytics";
import { Spinner } from "../../../../../components/Spinner";
import { decimal } from "global-analytics";

export function AnnualCountRow() {
	const { annualCount, annualRef } = useCommCpsModule();
	const { annualRenewalsCount } = useLmsCpsModule();

	const value =
		typeof annualCount === "number" && typeof annualRenewalsCount === "number"
			? annualCount - annualRenewalsCount
			: null;

	return (
		<TableRow ref={annualRef}>
			<TableCell align="left">Anuais</TableCell>
			{typeof value === "number" ? (
				<TableCell align="right">{decimal.format(value)}</TableCell>
			) : (
				<TableCell align="right">
					<Spinner />
				</TableCell>
			)}
		</TableRow>
	);
}
