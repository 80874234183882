"use client";

import { CommBlock } from "./comm/CommBlock";
import { AdsBlock } from "./ads/AdsBlock";
import { FunnelBlock } from "./funnel/FunnelBlock";
import { ConversionsBlock } from "./conversions/ConversionsBlock";
import { SupportBlock } from "./support/SupportBlock";
import { SocialBlock } from "./social/SocialBlock";
import { Box, Grid } from "@mui/material";
import {
	CommCpsModuleProvider,
	FunnelCpsModuleProvider,
	LmsCpsModuleProvider,
	MarketingCpsModuleProvider,
	PagesCpsModuleProvider,
	SocialCpsModuleProvider,
	SupportCpsModuleProvider,
} from "global-analytics";
import { ReactNode } from "react";
import { PagesBlock } from "./pages/PagesBlock";

function ScreenProviders({ children }: { children: ReactNode }) {
	return (
		<CommCpsModuleProvider>
			<LmsCpsModuleProvider>
				<SupportCpsModuleProvider>
					<FunnelCpsModuleProvider>
						<MarketingCpsModuleProvider>
							<PagesCpsModuleProvider>
								<SocialCpsModuleProvider>{children}</SocialCpsModuleProvider>
							</PagesCpsModuleProvider>
						</MarketingCpsModuleProvider>
					</FunnelCpsModuleProvider>
				</SupportCpsModuleProvider>
			</LmsCpsModuleProvider>
		</CommCpsModuleProvider>
	);
}

export default function MainScreen() {
	return (
		<ScreenProviders>
			<Grid container spacing={1} sx={{ p: 1 }}>
				<Grid item xl={4} lg={6} xs={12}>
					<SocialBlock />
					<ConversionsBlock />
					<FunnelBlock />
				</Grid>
				<Grid item xl={4} lg={6} xs={12}>
					<PagesBlock />
					<SupportBlock />
				</Grid>
				<Grid item xl={4} lg={6} xs={12}>
					<AdsBlock />
					<CommBlock />
				</Grid>
			</Grid>
			{/* Toolbar */}
			<Box sx={{ height: 50 }} />
		</ScreenProviders>
	);
}
