import { TableCell, TableRow } from "@mui/material";
import { decimalPercent, useCommCpsModule } from "global-analytics";
import { Spinner } from "../../../../../components/Spinner";

export function PixPercentRow() {
	const { pixPercent, pixRef } = useCommCpsModule();

	return (
		<TableRow ref={pixRef}>
			<TableCell align="left">Pix</TableCell>
			{typeof pixPercent === "number" ? (
				<TableCell align="right">{decimalPercent(pixPercent)}</TableCell>
			) : (
				<TableCell align="right">
					<Spinner />
				</TableCell>
			)}
		</TableRow>
	);
}
