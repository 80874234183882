"use client";

import { ReactNode, createContext, useContext } from "react";
import { FetcherInterface } from "../contracts/fetcher.interface";
import { FilterState } from "../contracts/filters";

export const GlobalAnalyticsContext = createContext<{
	filterState: FilterState;
	fetcher: FetcherInterface;
}>({
	filterState: {} as FilterState,
	fetcher: () => Promise.reject(),
});

export function GlobalAnalyticsProvider({
	filterState,
	fetcher,
	children,
}: {
	filterState: FilterState;
	fetcher: FetcherInterface;
	children: ReactNode;
}) {
	return (
		<GlobalAnalyticsContext.Provider
			value={{
				filterState,
				fetcher,
			}}
		>
			{children}
		</GlobalAnalyticsContext.Provider>
	);
}

export const useGlobalAnalytics = () => useContext(GlobalAnalyticsContext);
