import { TableCell, TableRow } from "@mui/material";
import { useCommCpsModule, useLmsCpsModule } from "global-analytics";
import { Spinner } from "../../../../../components/Spinner";
import { decimal } from "global-analytics";

export function SemiannualCountRow() {
	const {
		semiannualCount,
		semiannualRef,
		upgradeCount, //
	} = useCommCpsModule();
	const { semiannualRenewalsCount } = useLmsCpsModule();

	const value =
		typeof semiannualCount === "number" &&
		typeof semiannualRenewalsCount === "number" &&
		typeof upgradeCount === "number"
			? semiannualCount - (semiannualRenewalsCount + upgradeCount)
			: null;

	return (
		<TableRow ref={semiannualRef}>
			<TableCell align="left">Semestrais</TableCell>
			{typeof value === "number" ? (
				<TableCell align="right">{decimal.format(value)}</TableCell>
			) : (
				<TableCell align="right">
					<Spinner />
				</TableCell>
			)}
		</TableRow>
	);
}
