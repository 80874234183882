"use client";

import { ReactNode, createContext, useContext } from "react";
import { RenewalsValue, renewalsDefault, useRenewals } from "./renewals";

type LmsCpsModuleContextValue = RenewalsValue;

const LmsCpsModuleContext = createContext<LmsCpsModuleContextValue>({
	...renewalsDefault,
});

export function LmsCpsModuleProvider({ children }: { children: ReactNode }) {
	const renewals = useRenewals();

	return (
		<LmsCpsModuleContext.Provider
			value={{
				...renewals,
			}}
		>
			{children}
		</LmsCpsModuleContext.Provider>
	);
}

export function useLmsCpsModule() {
	return useContext(LmsCpsModuleContext);
}
