"use client";

import { ReactNode, createContext, useContext } from "react";
import {
	SalesPerProductValue,
	salesPerProductDefault,
	useSalesPerProduct,
} from "./sales-per-product";
import {
	PaymentMethodValue,
	paymentMethodDefault,
	usePaymentMethod,
} from "./payment-method";
import { SummaryValue, summaryDefault, useSummary } from "./summary";
import {
	SalesPerCategoryValue,
	salesPerCategoryDefault,
	useSalesPerCategory,
} from "./sales-per-category";

type CommCpsModuleContextValue = SummaryValue &
	PaymentMethodValue &
	SalesPerProductValue &
	SalesPerCategoryValue;

const CommCpsModuleContext = createContext<CommCpsModuleContextValue>({
	...summaryDefault,
	...paymentMethodDefault,
	...salesPerProductDefault,
	...salesPerCategoryDefault,
});

export function CommCpsModuleProvider({ children }: { children: ReactNode }) {
	const summary = useSummary();
	const paymentMethod = usePaymentMethod();
	const salesPerProduct = useSalesPerProduct();
	const salesPerCategory = useSalesPerCategory();

	return (
		<CommCpsModuleContext.Provider
			value={{
				...summary,
				...paymentMethod,
				...salesPerProduct,
				...salesPerCategory,
			}}
		>
			{children}
		</CommCpsModuleContext.Provider>
	);
}

export function useCommCpsModule() {
	return useContext(CommCpsModuleContext);
}
