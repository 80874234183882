"use client";

import { ReactNode, createContext, useContext } from "react";
import {
	recordsPerCategoryDefault,
	RecordsPerCategoryValue,
	useRecordsPerCategory,
} from "./records-per-category";
import {
	recordsPerOperatorDefault,
	RecordsPerOperatorValue,
	useRecordsPerOperator,
} from "./records-per-operator";

type SupportCpsModuleContextValue = RecordsPerCategoryValue &
	RecordsPerOperatorValue;

const SupportCpsModuleContext = createContext<SupportCpsModuleContextValue>({
	...recordsPerCategoryDefault,
	...recordsPerOperatorDefault,
});

export function SupportCpsModuleProvider({
	children,
}: {
	children: ReactNode;
}) {
	const recordsPerCategory = useRecordsPerCategory();
	const recordsPerOperator = useRecordsPerOperator();

	return (
		<SupportCpsModuleContext.Provider
			value={{
				...recordsPerCategory,
				...recordsPerOperator,
			}}
		>
			{children}
		</SupportCpsModuleContext.Provider>
	);
}

export function useSupportCpsModule() {
	return useContext(SupportCpsModuleContext);
}
