"use client";

import { RefObject, useRef } from "react";
import { coverageFromRange } from "../cps/coverage-factory";
import { useSumValuesRange } from "../cps/range-hooks";
import { CoveragePosition } from "../cps/types";
import { useGlobalAnalytics } from "../store/context";
import { moduleName } from "./constants";
import { useInView } from "framer-motion";

export type SalesPerCategoryValue = {
	medBrRef: RefObject<HTMLTableRowElement>;
	medBrCount: number | null;
	medExtRef: RefObject<HTMLTableRowElement>;
	medExtCount: number | null;
	pdRef: RefObject<HTMLTableRowElement>;
	pdCount: number | null;
	pdMedBrRef: RefObject<HTMLTableRowElement>;
	pdMedBrCount: number | null;
	pdMedExtRef: RefObject<HTMLTableRowElement>;
	pdMedExtCount: number | null;
	eocRef: RefObject<HTMLTableRowElement>;
	eocCount: number | null;
	othersRef: RefObject<HTMLTableRowElement>;
	othersCount: number | null;
};

export const salesPerCategoryDefault: SalesPerCategoryValue = {
	medBrRef: { current: null },
	medBrCount: null,
	medExtRef: { current: null },
	medExtCount: null,
	pdRef: { current: null },
	pdCount: null,
	pdMedBrRef: { current: null },
	pdMedBrCount: null,
	pdMedExtRef: { current: null },
	pdMedExtCount: null,
	eocRef: { current: null },
	eocCount: null,
	othersRef: { current: null },
	othersCount: null,
};

const useSalesPerCategorySum = (
	targetRef: RefObject<HTMLTableRowElement>,
	coverageFrom: CoveragePosition,
	coverageTo: CoveragePosition,
	category: string,
) => {
	const count = useSumValuesRange(
		moduleName,
		"sales-per-category-count",
		coverageFrom,
		coverageTo,
		{ category: [category] },
		useInView(targetRef),
	);

	return count.isLoading || count.error ? null : count.data?.sum ?? 0.0;
};

export const useSalesPerCategory = () => {
	const { filterState } = useGlobalAnalytics();
	const from = coverageFromRange(filterState.range, "start");
	const to = coverageFromRange(filterState.range, "end");

	const medBrRef = useRef<HTMLTableRowElement>(null);
	const medExtRef = useRef<HTMLTableRowElement>(null);
	const pdRef = useRef<HTMLTableRowElement>(null);
	const pdMedBrRef = useRef<HTMLTableRowElement>(null);
	const pdMedExtRef = useRef<HTMLTableRowElement>(null);
	const eocRef = useRef<HTMLTableRowElement>(null);
	const othersRef = useRef<HTMLTableRowElement>(null);

	return {
		medBrRef,
		medBrCount: useSalesPerCategorySum(medBrRef, from, to, "med-br"),
		medExtRef,
		medExtCount: useSalesPerCategorySum(medExtRef, from, to, "med-ext"),
		pdRef,
		pdCount: useSalesPerCategorySum(pdRef, from, to, "pd"),
		pdMedBrRef,
		pdMedBrCount: useSalesPerCategorySum(pdMedBrRef, from, to, "pd-med-br"),
		pdMedExtRef,
		pdMedExtCount: useSalesPerCategorySum(pdMedExtRef, from, to, "pd-med-ext"),
		eocRef,
		eocCount: useSalesPerCategorySum(eocRef, from, to, "eoc"),
		othersRef,
		othersCount: useSalesPerCategorySum(othersRef, from, to, "others"),
	};
};
